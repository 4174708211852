import { useEffect, useState } from "react";

import { Button, Icon, Label, Select } from "semantic-ui-react";

import { DropdownOptions } from "../../constants";

import { UserList, UserCreateForm, LoadingSpinner, ErrorMessage } from "../../components";

import { User } from "../../models";

import { UserService, HelperService } from "../../services";


export function UsersPage() {

    const [users, setUsers] = useState<User[]>([])
    const [userRole, setUserRole] = useState<string>('')
    const [loadingUsers, setLoadingUsers] = useState<boolean>(true)
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [errorFetchingUsers, setErrorFetchingUsers] = useState<boolean>(false)

    useEffect(() => {

        setUserRole(HelperService.getRoleFromToken())
        
        UserService.getUsersList()
            .then(data => setUsers(data))
            .catch(_ => setErrorFetchingUsers(true))
            .finally(() => setLoadingUsers(false))
    }, [])


    const addUserToList = (user: User) => setUsers([user, ...users])


    const showCreateUserForm = () => setCreateModalVisible(true)

    return <div>

        {loadingUsers && <LoadingSpinner />}

        {errorFetchingUsers && <ErrorMessage />}

        {
            !loadingUsers && !errorFetchingUsers &&
                <>
                    <div className='headerRow'>
                        <div className="flex-center">
                            <h3 className='mb-0 mr-25'><Label color='orange'>{users.length}</Label> &nbsp; { userRole === 'Admin' ? 'Users' : 'Utilisateurs' }</h3>
                            { userRole === 'Admin' && <Select className='mr-25' placeholder='Filtrer par compagnie' options={DropdownOptions.agenciesOptions} /> }
                            { userRole === 'Admin' && <Select className='mr-25' placeholder='Filtrer par agence' options={DropdownOptions.agenciesOptions} /> }
                        </div>
                        { userRole === 'Admin' && <Button color='orange' className='size-16' onClick={showCreateUserForm}><Icon name="add circle" />Nouvel agent</Button> }
                    </div>
                    <UserList users={users} />
                </>
        }
        {createModalVisible && <UserCreateForm setCreateModalVisible={setCreateModalVisible} addUserToList={addUserToList}/>}
    </div>
}