import React from 'react'

import { useNavigate } from "react-router-dom"

import { jwtDecode } from "jwt-decode"

import { AreaService, AuthService, CompanyService, AgencyService, HelperService } from '../services'

import { Button, Checkbox, Form, FormField, Icon, Message } from 'semantic-ui-react'

import css from './login-form.module.css'



export function LoginForm() {

    const navigate = useNavigate()
    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [logginError, setLoginError] = React.useState(false)

    const handleUsernameInput = (e: any) => setUsername(e.target.value)

    const handlePasswordInput = (e: any) => setPassword(e.target.value)


    const attemptLogin = (e: any) => {
        e.preventDefault()

        setLoading(true)

        AuthService.attemptLogin({ username, password })
            .then(accessToken => {

                const decoded = jwtDecode(accessToken) as any
                const route = AuthService.getRouteFromScope(decoded)
                HelperService.storeAccessToken(accessToken)

                if (decoded.scopes.includes('admin_sys')) {

                    console.log('[======== Prefetching areas ========]')
                    AreaService.getAreaList()
                        .then(data => HelperService.storeAreas(data))
                        .catch(err => { throw new Error(err) })


                    console.log('[======== Prefetching companies ========]')
                    CompanyService.getCompaniesList()
                        .then(data => HelperService.storeCompanies(data))
                        .catch(err => { throw new Error(err) })

                    console.log('[======== Prefetching agencies ========]')
                    AgencyService.getAgenciesList()
                        .then(data => HelperService.storeAgencies(data))
                        .catch(err => { throw new Error(err) })
                }

                navigate(route)
            })
            .catch(err => {
                setLoading(false)
                setLoginError(true)
            })
    }

    return <div>
        {logginError && <Message color='red'>Le login ou le mot de passe est incorrect</Message>}
        <br />

        <div className={css.formWrapper}>
            <p className='flex-center'><Icon name='user circle outline' size='huge' color='orange' /></p>
            <h2 className='flex-center'>Ravis de vous revoir</h2>
            <p className='flex-center'>Saisissez vos informations de connexion</p>
            <br />

            <Form size='large'>
                <FormField>
                    <label>Login</label>
                    <input placeholder='Numéro de téléphone' onInput={handleUsernameInput} />
                </FormField>
                <FormField>
                    <label>Mot de passe</label>
                    <input placeholder='Mot de passe' type='password' onInput={handlePasswordInput} />
                </FormField>
                {/* <FormField>
                    <Checkbox label="J'accepte les conditions d'utilisation" />
                </FormField> */}
                <br />
                <p className='flex-center'>
                <Button type='submit' color='orange' size='large' onClick={attemptLogin} loading={loading}>Se connecter<Icon name='long arrow alternate right' /></Button>
                </p>
            </Form>
        </div>
    </div>
}



