import { useEffect, useState } from 'react'
import { Icon, Form, Button, FormField, Radio, FormGroup } from 'semantic-ui-react'

import { ErrorMessage } from './error-message'
import { FeedbackMessage } from '../constants'


const approTypeOptions = [
    { key: 'c', text: 'Caisse', value: 'cash' },
    { key: 'm', text: 'Mobile money', value: 'mobile' }
]

const inlineStyle = {
    wrapper: {
        padding: '20px',
        borderRadius: '5px',
        // backgroundColor: '#f9fafb',
        minHeight: '170px',
        // width: '80%',
        // margin: 'auto',
        border: '2.8px dashed #e55b00',
    }
}



export function DistributorApproForm({ addToList, hideForm }: { addToList: Function, hideForm: Function }) {
    
    const [creating, setCreating] = useState(false)
    
    const handleFormSubmit = () => {
    
        setCreating(true)
    
    }


    return <div style={inlineStyle.wrapper}>

        <Form>
            <FormGroup widths='equal'>
                <FormField>
                    <label className='size-16'>Caisse</label>
                    <Form.Select options={approTypeOptions} placeholder='Caisse à approvisionner'/>
                </FormField>

                <FormField>
                    <label className='size-16'>Montant</label>
                    <input
                        type='number'
                        placeholder="Montant de l'approvisionnement en F CFA"
                    />
                </FormField>

                <FormField>
                    <label className='size-15'>Type</label>
                    <Form.Select options={approTypeOptions}  placeholder='Type des fonds'/>
                </FormField>
            </FormGroup>

            <br/>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button color='orange' content="Annuler" onClick={() => hideForm()} icon='trash alternate' />
                &nbsp;&nbsp;&nbsp;
                <Button color='black' content="Confirmer" onClick={handleFormSubmit} icon='check' loading={creating} />
            </div>
        </Form>
    </div>
}