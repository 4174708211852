import { Button, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react"
import { Provisionning } from "../models"
import { NoData } from "./no-data"
import { HelperService } from "../services"

export function DistributorApproList({ provisionnings }: { provisionnings: any[] }) {
    return <div>
        {
            provisionnings.length === 0 ? <NoData /> :

                <Table basic='very'>
                    <TableHeader>
                        <TableRow style={{ fontSize: '15px' }}>
                            <TableHeaderCell className="size-17">Compagnie</TableHeaderCell>
                            <TableHeaderCell className="size-17">Auteur</TableHeaderCell>
                            <TableHeaderCell className="size-17">Montant</TableHeaderCell>
                            <TableHeaderCell className="size-17">Type</TableHeaderCell>
                            <TableHeaderCell className="size-17">Statut</TableHeaderCell>
                            <TableHeaderCell className="size-17">Caisse</TableHeaderCell>
                            <TableHeaderCell className="size-17">Création</TableHeaderCell>
                            <TableHeaderCell className="size-17">Validation</TableHeaderCell>
                            <TableHeaderCell className="size-17" style={{ textAlign: 'center' }}>Actions</TableHeaderCell>
                        </TableRow>
                    </TableHeader>

                    <TableBody>

                        {/* {
                            provisionnings.map((provisionning, index) =>
                                <TableRow style={{ fontSize: '15px' }} key={provisionning.id}>
                                    <TableCell>{HelperService.formatAmount(provisionning.amount!)}</TableCell>
                                    <TableCell>{HelperService.formatDateTime(provisionning.createdAt!)}</TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                                        <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                                        <Button icon><Icon name='eye' /></Button>
                                    </TableCell>
                                </TableRow>)
                        } */}

                        <TableRow style={{ fontSize: '15px' }}>
                            <TableCell>Sendtext</TableCell>
                            <TableCell>Omar Diop</TableCell>
                            <TableCell>13 000</TableCell>
                            <TableCell>cash</TableCell>
                            <TableCell>en cours</TableCell>
                            <TableCell>Abdou Ndiaye</TableCell>
                            <TableCell>12 Oct. 2024 13:06</TableCell>
                            <TableCell>13 Oct. 2024 08:00</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                                <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                                <Button icon><Icon name='eye' /></Button>
                            </TableCell>
                        </TableRow>
                        <TableRow style={{ fontSize: '15px' }}>
                            <TableCell>Sendtext</TableCell>
                            <TableCell>Omar Diop</TableCell>
                            <TableCell>13 000</TableCell>
                            <TableCell>cash</TableCell>
                            <TableCell>en cours</TableCell>
                            <TableCell>Abdou Ndiaye</TableCell>
                            <TableCell>12 Oct. 2024 13:06</TableCell>
                            <TableCell>13 Oct. 2024 08:00</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                                <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                                <Button icon><Icon name='eye' /></Button>
                            </TableCell>
                        </TableRow>
                        <TableRow style={{ fontSize: '15px' }}>
                            <TableCell>Sendtext</TableCell>
                            <TableCell>Omar Diop</TableCell>
                            <TableCell>13 000</TableCell>
                            <TableCell>cash</TableCell>
                            <TableCell>en cours</TableCell>
                            <TableCell>Abdou Ndiaye</TableCell>
                            <TableCell>12 Oct. 2024 13:06</TableCell>
                            <TableCell>13 Oct. 2024 08:00</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                                <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                                <Button icon><Icon name='eye' /></Button>
                            </TableCell>
                        </TableRow>
                        <TableRow style={{ fontSize: '15px' }}>
                            <TableCell>Sendtext</TableCell>
                            <TableCell>Omar Diop</TableCell>
                            <TableCell>13 000</TableCell>
                            <TableCell>cash</TableCell>
                            <TableCell>en cours</TableCell>
                            <TableCell>Abdou Ndiaye</TableCell>
                            <TableCell>12 Oct. 2024 13:06</TableCell>
                            <TableCell>13 Oct. 2024 08:00</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                                <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                                <Button icon><Icon name='eye' /></Button>
                            </TableCell>
                        </TableRow>
                        <TableRow style={{ fontSize: '15px' }}>
                            <TableCell>Sendtext</TableCell>
                            <TableCell>Omar Diop</TableCell>
                            <TableCell>13 000</TableCell>
                            <TableCell>cash</TableCell>
                            <TableCell>en cours</TableCell>
                            <TableCell>Abdou Ndiaye</TableCell>
                            <TableCell>12 Oct. 2024 13:06</TableCell>
                            <TableCell>13 Oct. 2024 08:00</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                                <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                                <Button icon><Icon name='eye' /></Button>
                            </TableCell>
                        </TableRow>
                        <TableRow style={{ fontSize: '15px' }}>
                            <TableCell>Sendtext</TableCell>
                            <TableCell>Omar Diop</TableCell>
                            <TableCell>13 000</TableCell>
                            <TableCell>cash</TableCell>
                            <TableCell>en cours</TableCell>
                            <TableCell>Abdou Ndiaye</TableCell>
                            <TableCell>12 Oct. 2024 13:06</TableCell>
                            <TableCell>13 Oct. 2024 08:00</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                                <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                                <Button icon><Icon name='eye' /></Button>
                            </TableCell>
                        </TableRow>
                        <TableRow style={{ fontSize: '15px' }}>
                            <TableCell>Sendtext</TableCell>
                            <TableCell>Omar Diop</TableCell>
                            <TableCell>13 000</TableCell>
                            <TableCell>cash</TableCell>
                            <TableCell>en cours</TableCell>
                            <TableCell>Abdou Ndiaye</TableCell>
                            <TableCell>12 Oct. 2024 13:06</TableCell>
                            <TableCell>13 Oct. 2024 08:00</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                                <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                                <Button icon><Icon name='eye' /></Button>
                            </TableCell>
                        </TableRow>
                        <TableRow style={{ fontSize: '15px' }}>
                            <TableCell>Sendtext</TableCell>
                            <TableCell>Omar Diop</TableCell>
                            <TableCell>13 000</TableCell>
                            <TableCell>cash</TableCell>
                            <TableCell>en cours</TableCell>
                            <TableCell>Abdou Ndiaye</TableCell>
                            <TableCell>12 Oct. 2024 13:06</TableCell>
                            <TableCell>13 Oct. 2024 08:00</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                                <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                                <Button icon><Icon name='eye' /></Button>
                            </TableCell>
                        </TableRow>
                        <TableRow style={{ fontSize: '15px' }}>
                            <TableCell>Sendtext</TableCell>
                            <TableCell>Omar Diop</TableCell>
                            <TableCell>13 000</TableCell>
                            <TableCell>cash</TableCell>
                            <TableCell>en cours</TableCell>
                            <TableCell>Abdou Ndiaye</TableCell>
                            <TableCell>12 Oct. 2024 13:06</TableCell>
                            <TableCell>13 Oct. 2024 08:00</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                                <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                                <Button icon><Icon name='eye' /></Button>
                            </TableCell>
                        </TableRow>
                        <TableRow style={{ fontSize: '15px' }}>
                            <TableCell>Sendtext</TableCell>
                            <TableCell>Omar Diop</TableCell>
                            <TableCell>13 000</TableCell>
                            <TableCell>cash</TableCell>
                            <TableCell>en cours</TableCell>
                            <TableCell>Abdou Ndiaye</TableCell>
                            <TableCell>12 Oct. 2024 13:06</TableCell>
                            <TableCell>13 Oct. 2024 08:00</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                                <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                                <Button icon><Icon name='eye' /></Button>
                            </TableCell>
                        </TableRow>
            
                    </TableBody>
                </Table>
        }
    </div>
}





// public id?: number;
// public initiatorId?: number;
// public tillId?: number;
// public updatedAt?: Date;


// public initiatorPermission?: string;
// cashier_name
// public amount?: number;
// public createdAt?: Date;
// public validatedBycashierAt?: Date;
// public status?: string;
// public approType?: string;
// public companyId?: number;
// public comments?: string;


// rajouter companyName
// rajouter cashier_name
// rajouter till_name
// rajouter initiator_name