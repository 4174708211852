import { HelperService } from "../services"

export class User {
    id?: number
    firstname?: string
    lastname?: string
    sex?: string
    address?: string
    phone?: string
    email?: string
    nin?: string
    cniRectoPath?: string
    cniVersoPath?: string
    maritalStatus?: string
    salary?: number
    isActive?: boolean
    permissions?: any[]
    createdAt?: Date
    updatedAt?: Date
    agency?: any
    company?: any
    role?: string
    password?: string


    constructor(args:{ id?: number, firstname?: string, lastname?: string, sex?: string, address?: string,
        phone?: string, email?: string, nin?: string, cniRectoPath?: string, cniVersoPath?: string, maritalStatus?: string,
        salary?: number, isActive?: boolean, company?: any, agency?: any,
        permissions?: any[], role?: string, createdAt?: Date, updatedAt?: Date, password?: string }) {  

            this.id = args.id
            this.firstname = args.firstname
            this.lastname = args.lastname
            this.sex = args.sex
            this.address = args.address
            this.phone = args.phone
            this.email = args.email
            this.nin = args.nin
            this.cniRectoPath = args.cniRectoPath
            this.cniVersoPath = args.cniVersoPath
            this.maritalStatus = args.maritalStatus
            this.salary = args.salary
            this.isActive = args.isActive
            this.agency = args.agency
            this.company = args.company
            this.permissions = args.permissions
            this.role = args.role
            this.createdAt = args.createdAt
            this.updatedAt = args.updatedAt
            this.password = args.password
    }

    static fromJSON(jsonData: any): User {
        return new User({
            id: jsonData.id,
            firstname: jsonData.firstname,
            lastname: jsonData.lastname,
            sex: jsonData.sex,
            address: jsonData.address,
            phone: jsonData.phone,
            email: jsonData.email,
            nin: jsonData.nin || '',
            cniRectoPath: jsonData.cni_recto_path,
            cniVersoPath: jsonData.cni_verso_path,
            maritalStatus: jsonData.marital_status,
            salary: jsonData.salary || 0,
            isActive: jsonData.is_active,
            agency: jsonData.agency || {},
            company: jsonData.company,
            permissions: jsonData.permissions,
            role: HelperService.getRoleFromScopes(jsonData.permissions),
            createdAt: new Date(jsonData.created_at),
            updatedAt: new Date(jsonData.update)
        })
    }

    toJSON(): any {

        return JSON.stringify({
            firstname: this.firstname,
            lastname: this.lastname,
            sex: this.sex,
            address: this.address,
            phone: this.phone,
            email: this.email,
            is_active: this.isActive,
            is_admin: false,
            nin: this.nin,
            company_id: this.company.id,
            agency_id: this.agency.id,
            cni_recto_path: this.cniRectoPath,
            cni_verso_path: this.cniVersoPath,
            marital_status: this.maritalStatus,
            salary: this.salary,
            password: this.password,
            permissions_id: this.permissions
        })
    }
}