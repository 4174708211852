import axios, { AxiosInstance } from "axios";


let instance: AxiosInstance

const devApiBaseUrl = 'http://localhost:8000/api'

const prodApiBaseUrl = 'https://transfert.ligueye.com/api'

const isProd = window.location.hostname === 'transfert.ligueye.com' 

const apiBaseUrl = isProd ? prodApiBaseUrl : devApiBaseUrl


const  dumpRequest = (data: any, headers: any): any => {

    const accessToken = localStorage.getItem('okdev_xth_token')
    
    if (accessToken) headers['Authorization'] = 'Bearer ' + accessToken
    
    headers['Content-Type'] = 'application/json'

    return data;
}

const dumpResponse = (data: any, headers: any, status: any): any => {

    if(status >= 400)

        console.log('API ERROR:', data)

    return JSON.parse(data);
}


export const getAxiosInstance = (baseURL = apiBaseUrl): AxiosInstance => {

    if (instance && instance.defaults.baseURL === baseURL)
        return instance

    instance = axios.create({
        baseURL: baseURL,
        timeout: 10000,
        transformRequest: dumpRequest,
        transformResponse: dumpResponse
    });

    instance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
 
        if(error.response.status === 401)
            window.location.href = '/'

        return Promise.reject(error);
    })

    return instance
}



