
import { useEffect, useState } from 'react'

import { Button, Label, Select } from 'semantic-ui-react'

import { DropdownOptions } from '../../constants'

import { AgencyList, AgencyCreateForm, LoadingSpinner, ErrorMessage } from '../../components'

import { Agency } from '../../models'

import { AgencyService, HelperService } from '../../services'


export function AgenciesPage() {

    const [userRole, setUserRole] = useState<string>('')
    const [agencies, setAgencies] = useState<Agency[]>([])
    const [loadingAgencies, setLoadingAgencies] = useState<boolean>(true)
    const [errorFetchingAgencies, setErrorFetchingAgencies] = useState<boolean>(false)
    const [createModalVisible, setCreateModalVisible] = useState(false)


    useEffect(() => {
  
        setUserRole(HelperService.getRoleFromToken())

        const storedAgencies = HelperService.retrieveAgencies()
        
        if(storedAgencies.length > 0) {
            setAgencies(storedAgencies)
            setLoadingAgencies(false)
            return
        }

        AgencyService.getAgenciesList()
            .then(data => setAgencies(data))
            .catch(err => setErrorFetchingAgencies(true))
            .finally(() => setLoadingAgencies(false))

    }, [])


    const addAgencyToList = (agency: Agency) => {

        setAgencies([agency, ...agencies])
        
        HelperService.storeAgencies([agency, ...agencies])

    }


    return <div>

        { errorFetchingAgencies && <ErrorMessage/> }

        { loadingAgencies && <LoadingSpinner /> }

        { !loadingAgencies && !errorFetchingAgencies &&
            <>
                <div className='headerRow'>
                    <div className='flex-center'>
                        <h3 className='mb-0 mr-25' ><Label color='orange'>{agencies.length}</Label> agences</h3>
                        { userRole === 'Admin' && <Select className='mr-25' placeholder='Filtrer par région' options={DropdownOptions.regionOptions} /> }
                        { userRole === 'Admin' && <Select className='mr-25' placeholder='Filtrer par département' options={DropdownOptions.departementsOptions} /> }
                        { userRole === 'Admin' && <Select className='mr-25' placeholder='Filtrer par zone' options={DropdownOptions.areaOptions} /> }
                    </div>
                    { userRole === 'Admin' && <Button color='orange' className='size-16' onClick={() => setCreateModalVisible(true)}>Nouveau</Button> }
                </div>
                <AgencyList agencies={agencies}  userRole={userRole}/>
            </>
        }

        {createModalVisible && <AgencyCreateForm setCreateModalVisible={setCreateModalVisible} addAgencyToList={addAgencyToList}/>}

    </div>
}