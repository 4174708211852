import { Company } from "../models"

import { Button, Icon, Label } from "semantic-ui-react"

import css from './company-profile.module.css'

export function CompanyProfileView({c, showDeleteModal}: {c: Company, showDeleteModal: Function}) {

    return (
        <div className={css.profileWrapper}>
            <h2 className={css.companyTitle}>{c.name} 
                &nbsp;&nbsp; 
                <span>#{c.id}</span>  
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Label color={c.isActive ? 'green' : 'red'}> {c.isActive ? "Compte Actif" : "Compte Inactif"}</Label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Icon name="edit" size="small" className={css.companyEditBtn}/>
                {/* <Button circular icon='edit' /> */}
            </h2>
            <p className="mb-8 size-16">
                <Icon name="phone"/> Téléphone: {c.phone}
            </p>
            <p className="mb-8 size-16">
                <Icon name="mail"/> 
                Email: {c.email}
            </p>
            <p className="mb-8 size-16">
                <Icon name="user"/> 
                Directeur: {c.chiefName}
            </p>
            <p className="mb-8 size-16">
                <Icon name="users"/> 
                <span className="hov-underline-pointer">{c.agentsCount} Agents &nbsp;<Icon name="external alternate" size="small" className={css.externalLink}/></span>
            </p>
            <p className="mb-8 size-16">
                <Icon name="map marker alternate"/>
                <span className="hov-underline-pointer">{c.agenciesCount} Agences &nbsp;<Icon name="external alternate" size="small" className={css.externalLink}/></span>
            </p>
            <p className="mb-8 size-16">
                <Icon name="inbox"/>
                <span className="hov-underline-pointer">{c.tillsCount} Caisses &nbsp;<Icon name="external alternate" size="small" className={css.externalLink}/></span>
            </p>
            <p className="mb-8 size-16">
                <Icon name="tag"/>
                Description: {c.description}
            </p>

            <br/>
            <br/>

            <div>
                <h2 className={css.companyAdmin}>
                    Admin Compagnie
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Label color={c.admin.is_active ? 'green' : 'red'}> {c.admin.is_active ? "Compte Actif" : "Compte Inactif"}</Label>
                </h2>

                <p className={css.adminInfoRow}>
                    <span>Identifiant</span>
                    <strong>#{c.admin.id}</strong>
                </p>
                <p className={css.adminInfoRow}>
                    <span>Prénom</span>
                    <strong>{c.admin.firstname}</strong>
                </p>
                <p className={css.adminInfoRow}>
                    <span>Nom de famille</span>
                    <strong>{c.admin.lastname}</strong>
                </p>
                <p className={css.adminInfoRow}>
                    <span>Téléphone</span>
                    <strong>{c.admin.phone}</strong>
                </p>
                <p className={css.adminInfoRow}>
                    <span>Adresse</span>
                    <strong>{c.admin.address}</strong>
                </p>
                <p className={css.adminInfoRow}>
                    <span>Email</span>
                    <strong>{c.admin.email}</strong>
                </p>
                <p className={css.adminInfoRow}>
                    <span>Numéro CNI</span>
                    <strong>{c.admin.nin}</strong>
                </p>
                <p className={css.adminInfoRow}>
                    <span>Situation Matrimoniale</span>
                    <strong>{c.admin.marital_status}</strong>
                </p>
                <p className={css.adminInfoRow}>
                    <span>Date de création</span>
                    <strong>{new Date(c.admin.created_at).toLocaleString('FR')}</strong>
                </p>
            </div>

            <br/>
            <br/>

            <div className={css.separator}></div>
            <br/>
            <div className={css.actionButtons}>
                <Button color='red' onClick={() => showDeleteModal(true)}><Icon name="trash alternate"/>Supprimer</Button> &nbsp;
                <Button color='orange'><Icon name="cube"/> Transactions</Button> &nbsp;
                <Button color='yellow'><Icon name="lock"/> Bloquer</Button>
            </div>
        </div> 
    )
}