export const FeedbackMessage = {
    REQUIRED: 'Ce champ est requis',
    INVALID_EMAIL: 'Adresse email invalide',
    INVALID_PHONE: 'Numéro de téléphone invalide',
    INVALID_PASSWORD: 'Mot de passe invalide',
    GENERIC_ERROR: 'Nous avons rencontré une erreur. Veuillez réessayer ou contacter les admins',
    COMPANY_CREATE_ERROR: 'Erreur lors de la création de la compagnie',
    COMPANY_UPDATE_ERROR: 'Erreur lors de la mise à jour de la compagnie',
    COMPANY_DELETE_ERROR: 'Erreur lors de la suppression de la compagnie',
    COMPANY_FETCH_ERROR: 'Erreur lors de la récupération des compagnies',
    COMPANY_NOT_FOUND: 'Compagnie introuvable',
    AREA_CREATE_ERROR: 'Erreur lors de la création de la zone',
    AREA_UPDATE_ERROR: 'Erreur lors de la mise à jour de la zone',
    AREA_DELETE_ERROR: 'Erreur lors de la suppression de la zone',
    AREA_FETCH_ERROR: 'Erreur lors de la récupération des zones',
    AREA_NOT_FOUND: 'Zone introuvable',
    AGENCY_CREATE_ERROR: 'Erreur lors de la création de l\'agence',
    AGENCY_UPDATE_ERROR: 'Erreur lors de la mise à jour de l\'agence',
    AGENCY_DELETE_ERROR: 'Erreur lors de la suppression de l\'agence',
    AGENCY_FETCH_ERROR: 'Erreur lors de la récupération des agences',
    AGENCY_NOT_FOUND: 'Agence introuvable',
    SERVICE_CREATE_ERROR: 'Erreur lors de la création du service',
    SERVICE_UPDATE_ERROR: 'Erreur lors de la mise à jour du service',
    SERVICE_DELETE_ERROR: 'Erreur lors de la suppression du service',
    SERVICE_FETCH_ERROR: 'Erreur lors de la récupération des services mobile money',
    SERVICE_NOT_FOUND: 'Service introuvable',
    TILL_CREATE_ERROR: 'Erreur lors de la création de la caisse',
    TILL_UPDATE_ERROR: 'Erreur lors de la mise à jour de la caisse',
    TILL_DELETE_ERROR: 'Erreur lors de la suppression de la caisse',
    TILL_FETCH_ERROR: 'Erreur lors de la récupération des caisses',
    TILL_NOT_FOUND: 'Caisse introuvable',
    USER_CREATE_ERROR: 'Erreur lors de la création de l\'utilisateur',
    PARTNER_CREATE_ERROR: 'Erreur lors de la création du partenaire',
    PARTNER_UPDATE_ERROR: 'Erreur lors de la mise à jour du partenaire',
    PARTNER_DELETE_ERROR: 'Erreur lors de la suppression du partenaire',
    PARTNER_FETCH_ERROR: 'Erreur lors de la récupération des partenaires',
    PARTNER_NOT_FOUND: 'Partenaire introuvable',
    CASH_RECEPTION_CREATE_ERROR: 'Erreur lors de l\'enregistrement de la réception de fonds',
    CASH_RECEPTION_FETCH_ERROR: 'Erreur lors de la récupération de l\'historique des réceptions de fonds',
}