import { useState } from "react";
import { Button, Icon } from "semantic-ui-react";

import { DistributorApproForm, DistributorApproList } from "../../components";
import { CashReception } from "../../models";
import { create } from "domain";

export function DistributorProvisionningPage() {

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [cashReceptions, setCashReceptions] = useState<CashReception[]>([])

    const addToList = (cashReception: CashReception) => setCashReceptions([cashReception, ...cashReceptions])
    
    const hideForm = () => setCreateModalVisible(false)

    return <div>
        <div className='headerRow'>
            <h2>Appro caisse</h2>
           {
            !createModalVisible &&  
                <Button color='orange' className='size-16' onClick={() => setCreateModalVisible(!createModalVisible)}>
                    <Icon name="external alternate" /> Nouvel appro
                </Button>
            }
        </div>

        <div style={
            {
                height: createModalVisible ? '190px' : '0px',
                overflow: 'hidden',
                transition: 'height 0.5s ease-in-out',
                marginBottom: createModalVisible ? '30px' : '0px'
            }}>
            <DistributorApproForm addToList={addToList} hideForm={hideForm} />
        </div>

        <DistributorApproList provisionnings={[1, 2, 3]} />
    </div>
}