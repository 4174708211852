import { Button, Header, Icon, Label, Table } from 'semantic-ui-react'

import { useNavigate } from 'react-router-dom'

import { Company } from '../models'

import { NoData } from './no-data'


export function CompaniesListView({companies}: {companies: Partial<Company>[]}) {

    const navigate = useNavigate()

    const navigateToCompanyProfile = (id: number | undefined) => navigate(`/admin_sys/companies/${id}`)

    return <div>
        {

        companies.length === 0 ? 
        <NoData/> :
        <Table basic='very'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell>Compagnie</Table.HeaderCell>
                    <Table.HeaderCell>Compagnie Tel</Table.HeaderCell>
                    <Table.HeaderCell>Gérant</Table.HeaderCell>
                    <Table.HeaderCell>Agents</Table.HeaderCell>
                    <Table.HeaderCell>Caisses</Table.HeaderCell>
                    <Table.HeaderCell>Statut</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'></Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>

                {companies.map(c =>
                    <Table.Row key={c.id}>
                        <Table.Cell>{c.id}</Table.Cell>
                        <Table.Cell>
                            <Header as='h4' image>
                                <Icon name='linkify' />
                                <Header.Content>
                                    {c.name}
                                    <Header.Subheader>{c.description?.slice(0, 30)}</Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{c.phone}</Table.Cell>
                        <Table.Cell>{c.chiefName}</Table.Cell>
                        <Table.Cell>{c.agentsCount}</Table.Cell>
                        <Table.Cell>{c.tillsCount}</Table.Cell>
                        <Table.Cell><Label color={c.isActive ? 'green' : 'red'}> <span className='text-bold'>{c.isActive ? "Actif" : "INACTIF"}</span></Label></Table.Cell>
                        <Table.Cell textAlign='right'>
                            <Button icon className='mr-10'><Icon name='cloud download' /></Button>
                            <Button icon className='mr-10'><Icon name='pencil alternate'/></Button>
                            <Button icon onClick={() => navigateToCompanyProfile(c.id)}><Icon name='eye'/></Button>
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>  
             }     
    </div>
}