import { getAxiosInstance } from "../http"

import { CashReception } from "../models"

export class CashReceptionService {
    
    static getCashReceptionHistory = async (): Promise<CashReception[]> => {

        const instance = getAxiosInstance()

        const response = await instance.get('distributors/receptions')

        const result = response.data.map((cr: any) => CashReception.fromJSON(cr) as CashReception)

        return result
    }

    static createCashReception = async (cashReception: CashReception): Promise<CashReception> => {
        
        const instance = getAxiosInstance()

        console.log('cashReception', CashReception.toJSON(cashReception))

        const response = await instance.post('distributors/receptions', CashReception.toJSON(cashReception))

        return CashReception.fromJSON(response.data)
    }
}