import { MutableRefObject, useState, useRef, ChangeEvent, MouseEvent } from 'react'

import { Button, Modal, Header, Icon, Step, Form, Grid, Message } from 'semantic-ui-react'

import { UploadService, CompanyService, HelperService } from '../services'

import { DropdownOptions, FeedbackMessage } from '../constants'

import { ErrorMessage } from './error-message'

import { Company } from '../models'

import css from './company-create.module.css'

export function CompanyCreateForm({setCreateModalVisible, addCompanyToList} : {setCreateModalVisible: Function, addCompanyToList: Function}) {

    const cniRectoRef = useRef<any>(null)
    const cniVersoRef = useRef<any>(null)

    const [adminNin, setAdminNin] = useState('')
    const [adminSex, setAdminSex] = useState('')

    const [companyName, setCompanyName] = useState('')
    const [companyPhone, setCompanyPhone] = useState('')
    const [companyEmail, setCompanyEmail] = useState('')
    const [companyAddress, setCompanyAdress] = useState('')
    const [companyDirector, setCompanyDirector] = useState('')
    const [companyDescription, setCompanyDescription] = useState('')

    const [adminAddress, setAdminAddress] = useState('')
    const [adminPassword, setAdminPassword] = useState(HelperService.genStrongPassword())
    const [adminLastname, setAdminLastname] = useState('')
    const [adminFirstname, setAdminFirstname] = useState('')
    const [adminPhone, setAdminPhone] = useState('')
    const [adminEmail, setAdminEmail] = useState('')
    const [cniRectoPath, setCniRectoPath] = useState('')
    const [cniVersoPath, setCniVersoPath] = useState('')
    const [maritalStatus, setMaritalStatus] = useState('')


    const [companyCreationLoading, setCompanyCreationLoading] = useState(false)
    const [companyCreationError, setCompanyCreationError] = useState(false)


    const [stepValue, setStepValue] = useState<number>(1)


    const nextStep = () => setStepValue((v: number) => v + 1)

    const prevStep = () => {

        if (stepValue === 1) {
            setCreateModalVisible(false)
            return
        }

        setStepValue((v: number) => v - 1)
    }

    const handleCniRectoChange = async (e: ChangeEvent<HTMLInputElement>) => {

        const file = HelperService.getFile(e)

        if (file) {

            showPreview(file, cniRectoRef)

            const path = await UploadService.uploadFile(file)

            setCniRectoPath(path)
        }
    }

    const handleCniVersoChange = async (e: ChangeEvent<HTMLInputElement>) => {

        const file = HelperService.getFile(e)

        if (file) {
            showPreview(file, cniVersoRef)

            const path = await UploadService.uploadFile(file)

            setCniVersoPath(path)
        }
    }

    const showPreview = (file: File, imagePreviewRef: MutableRefObject<any>) => {

        const fileReader = new FileReader()

        fileReader.readAsDataURL(file);

        fileReader.onload = (e: any) => imagePreviewRef.current.src = e.target.result
    }

    const handleFormSubmit = (e: MouseEvent<HTMLButtonElement>) => {
 
        const formValues = getFormValues()
   
        submitFormValues(formValues)
    }

    const getFormValues = () => {
        return {
            firstname: adminFirstname,
            lastname: adminLastname,
            sex: adminSex === 'Homme' ? 'M' : 'F',
            address: adminAddress,
            phone: adminPhone,
            is_active: true,
            email: adminEmail,
            nin: adminNin,
            cni_recto_path: cniRectoPath,
            cni_verso_path: cniVersoPath,
            marital_status: maritalStatus,
            password: adminPassword,
            company: {
                name: companyName,
                phone: companyPhone,
                chief_name: companyDirector,
                address: companyAddress,
                email: companyEmail,
                is_active: true,
                description: companyDescription
            },
            permissions_id: [2, 3, 4, 5]
        }
    }

    const submitFormValues = (company: any): void => {

        setCompanyCreationLoading(true)

        CompanyService.createCompanyWithAdmin(company)
            .then(res => {
               
                const c = new Company({
                    id: res.company.id,
                    name: res.company.name,
                    description: res.company.description,
                    phone: res.company.phone,
                    chiefName: res.company.chief_name,
                    tillsCount: 0,
                    agentsCount: 0,
                    agenciesCount: 0,
                    isActive: true
                })

                addCompanyToList(c)
                setCreateModalVisible(false)
            })
            .catch(__ => setCompanyCreationError(true))
            .finally(() => setCompanyCreationLoading(false))
    }

    return <Modal
        onClose={() => setCreateModalVisible(false)}
        onOpen={() => setCreateModalVisible(true)}
        open={true}>
        <Modal.Content>
            <Modal.Description>
                <Header>Nouvelle companie</Header>
                { companyCreationError && <ErrorMessage errorText={FeedbackMessage.COMPANY_CREATE_ERROR} />}
                <Step.Group widths={3}>
                    <Step active={stepValue === 1}>
                        <Icon name='info' />
                        <Step.Content>
                            <Step.Title>Compagnie</Step.Title>
                            <Step.Description>Infos sur la compagnie</Step.Description>
                        </Step.Content>
                    </Step>

                    <Step active={stepValue === 2}>
                        <Icon name='user' />
                        <Step.Content>
                            <Step.Title>Admin</Step.Title>
                            <Step.Description>Infos sur l'Admin</Step.Description>
                        </Step.Content>
                    </Step>

                    <Step active={stepValue === 3}>
                        <Icon name='credit card' />
                        <Step.Content>
                            <Step.Title>Pièces</Step.Title>
                            <Step.Description>CNI de l'Admin</Step.Description>
                        </Step.Content>
                    </Step>
                </Step.Group>

                <Form error={false}>
                    <Message
                        error
                        header='Action Forbidden'
                        content='You can only sign up for an account once with a given e-mail address.' />
                    {
                        stepValue === 1 && <div>
                            <Form.Group widths='equal'>
                                <Form.Input type='text' fluid label='Compagnie' placeholder='Nom compagnie' value={companyName} onChange={e => setCompanyName(e.target.value)} />
                                <Form.Input type='text' fluid label='Directeur' placeholder='Nom directeur' value={companyDirector} onChange={e => setCompanyDirector(e.target.value)} />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input type='text' fluid label='Adresse' placeholder='Adresse compagnie' value={companyAddress} onChange={e => setCompanyAdress(e.target.value)} />
                                <Form.Input type='phone' fluid label='Téléphone' placeholder='Téléphone compagnie' value={companyPhone} onChange={e => setCompanyPhone(e.target.value)} />
                                <Form.Input type='email' fluid label='Email' placeholder='Email' value={companyEmail} onChange={e => setCompanyEmail(e.target.value)} />
                            </Form.Group>

                            <Form.TextArea label='Description' placeholder='Description optionnelle de la compagnie' value={companyDescription} onChange={e => setCompanyDescription(e.target.value)} rows={2} />
                        </div>
                    }

                    {
                        stepValue === 2 && <div>
                            <Form.Group widths='equal'>
                                <Form.Input label='First name' placeholder='First name' value={adminFirstname} onChange={e => setAdminFirstname(e.target.value)} />
                                <Form.Input fluid label='Last name' placeholder='Last name' value={adminLastname} onChange={e => setAdminLastname(e.target.value)} />
                                <Form.Select fluid label='Sexe' options={DropdownOptions.sexOptions} placeholder='homme ou femme' value={adminSex} onChange={(e: any) => setAdminSex(e.target.textContent)} />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input type='text' fluid label='Adresse' placeholder='Adresse' value={adminAddress} onChange={e => setAdminAddress(e.target.value)} />
                                <Form.Input type='phone' fluid label='Téléphone' placeholder='Téléphone' value={adminPhone} onChange={e => setAdminPhone(e.target.value)} />
                                <Form.Input type='email' fluid label='Email' placeholder='Email' value={adminEmail} onChange={e => setAdminEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input fluid label='NIN' placeholder='Numéro pièce' value={adminNin} onChange={e => setAdminNin(e.target.value)} />
                                <Form.Input fluid label='Password' placeholder='Password' value={adminPassword} onChange={e => setAdminPassword(e.target.value)} />
                                <Form.Select fluid label='Statut matrimonial' options={DropdownOptions.maritalStatusOptions} placeholder='Statut' value={maritalStatus} onChange={(e: any) => setMaritalStatus(e.target.textContent)} />
                            </Form.Group>
                        </div>
                    }
                    {
                        stepValue === 3 && <div>
                            <br />
                            <Grid columns={2} divided centered>
                                <Grid.Row centered>
                                    <Grid.Column className={css.centeredContent}>
                                        <label htmlFor='cni_recto' className={css.inputLabel}>
                                            <img ref={cniRectoRef} src='/images/placeholder.png' className={css.cniPreview} alt='' />
                                        </label>
                                        <input type='file' id='cni_recto' onChange={handleCniRectoChange} />
                                        <h3>CNI recto</h3>
                                    </Grid.Column>
                                    <Grid.Column className={css.centeredContent}>
                                        <label htmlFor='cni_verso' className={css.inputLabel}>
                                            <img ref={cniVersoRef} src='/images/placeholder.png' className={css.cniPreview} alt='' />
                                        </label>
                                        <input type='file' id='cni_verso' onChange={handleCniVersoChange} />
                                        <h3>CNI verso</h3>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    }
                </Form>

            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            {stepValue > 1 && <Button color='black' onClick={prevStep}>Précédent</Button>}
            {stepValue < 3 && <Button color='orange' onClick={nextStep}>Suivant</Button>}
            {stepValue === 3 && <Button color='orange' loading={companyCreationLoading} content='Sauvegarder' type='submit' onClick={handleFormSubmit} />}
        </Modal.Actions>
    </Modal>
}