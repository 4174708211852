export class Partner {
    public id?: number;
    public name?: string;
    public phone?: string;
    public address?: string;
    public contactFullname?: string;
    public comment?: string;
    public companyId?: number;
    public companyName?: string;


    constructor(args: {id?: number, name?: string, phone?: string, address?: string, contactFullname?: string, comment?: string, companyId?: number, companyName?: string}) {
        this.id = args.id
        this.name = args.name
        this.phone = args.phone
        this.address = args.address
        this.contactFullname = args.contactFullname
        this.comment = args.comment
        this.companyId = args.companyId
        this.companyName = args.companyName
    }

    static fromJSON(jsonData: any): Partner {
        return new Partner({
            id: jsonData.id,
            name: jsonData.name,
            phone: jsonData.phone,
            address: jsonData.address,
            contactFullname: jsonData.contact_fullname,
            comment: jsonData.comments,
            companyId: jsonData.company_id,
            companyName: jsonData.company_name
        })  
    }


    static toJSON(partner: Partner): any {
        return JSON.stringify({
            id: partner.id,
            name: partner.name,
            phone: partner.phone,
            address: partner.address,
            contact_fullname: partner.contactFullname,
            comments: partner.comment,
            company_id: partner.companyId
        })
    }

}