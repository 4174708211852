import { ChangeEvent } from 'react'

import { jwtDecode } from "jwt-decode"

import { Agency, Area, Company } from "../models"

export class HelperService {

    static genStrongPassword = (): string => {

        let dico = '@#$ABCDEFGHI@#$ABCDEFGHIJKLMNOPQRS@#$TUVWXYZ123456@#$ABCDEFGHIJKLMNOPQRS@#$TUVWXYZ123456789abcdefghijklmnopqrstuvwxyz@#$789abcdefghijklmnopqrstuvwxyz@#$JKLMNOPQRS@#$TUVWXYZ123456789abcdefghijklmnopqrstuvwxyz@#$'

        dico = dico.repeat(10)


        return this.shuffleStr(dico).slice(17, 25)
    }

    static shuffleStr = (str: string) => {
        return str.split('')
            .sort(_ => Math.random() - 0.5)
            .sort(_ => Math.random() - 0.5)
            .sort(_ => Math.random() - 0.5)
            .sort(_ => Math.random() - 0.5)
            .join('')
    }

    static getCompanyName = (): string => {

        let name = localStorage.getItem('okdev__companyName')

        if(name)
            return name

        const urlParts = window.location.href.split('/')

        name = urlParts[urlParts.length - 2]

        localStorage.setItem('okdev__companyName', name)

        return name
    }

    static getCurrentPath = (): string => {

        const urlParts = window.location.href.split('/')

        const path = urlParts[urlParts.length - 1]

        console.log('path', path)

        return path
    }

    static getRoleFromScopes = (permissions: string[]): string => {

        const permissionNames = permissions.map((p: any) => p.name)

            if(permissionNames.includes('admin_sys'))
                return 'Admin'

            if(permissionNames.includes('company_supervisor'))
                return 'Superviseur'

            if(permissionNames.includes('company_general_distributor'))
                return 'Dist General'

            if(permissionNames.includes('company_local_distributor'))
               return 'Dist Local'

            if(permissionNames.includes('company_cashier'))
                return 'Caissier'
            
            return ''
    }


    static getFile = (e: ChangeEvent<HTMLInputElement>): File | null => {

        if (!e.target.files || e.target.files.length === 0) return null

        const acceptedTypes = ['application/pdf', 'image/png', 'image/jpeg']

        const selectedFile = e.target.files[0]

        if (!acceptedTypes.includes(selectedFile.type)) return null

        return selectedFile
    }

    
    static storeAccessToken = (accessToken: string) => localStorage.setItem('okdev_xth_token', accessToken)

    static storeAreas = (data: any) => localStorage.setItem('okdev_areas', JSON.stringify(data))

    static retrieveAreas = (): Area[] => JSON.parse(localStorage.getItem('okdev_areas') || '[]')

    static storeCompanies = (data: Company[]) => localStorage.setItem('okdev_companies', JSON.stringify(data))

    static retrieveCompanies = (): Company[] => JSON.parse(localStorage.getItem('okdev_companies') || '[]')

    static storeAgencies = (data: any) => localStorage.setItem('okdev_agencies', JSON.stringify(data))

    static retrieveAgencies = (): Agency[] => JSON.parse(localStorage.getItem('okdev_agencies') || '[]')

    static clearStorage = () => {

        localStorage.removeItem('okdev_xth_token')

        localStorage.removeItem('okdev_areas')

        localStorage.removeItem('okdev__companyName')

        localStorage.removeItem('okdev_companies')

        localStorage.removeItem('okdev_agencies')
    }

    static getRoleFromToken = (): string => {

        const token= localStorage.getItem('okdev_xth_token') 

        if(!token) return ''

        const decoded = jwtDecode(token) as any
        
        if(decoded.scopes.includes('admin_sys'))
            return 'Admin'

        if(decoded.scopes.includes('company_supervisor'))
            return 'Superviseur'

        if(decoded.scopes.includes('company_general_distributor'))
            return 'Dist General'

        if(decoded.scopes.includes('company_local_distributor'))
           return 'Dist Local'

        if(decoded.scopes.includes('company_cashier'))
            return 'Caissier'
        
        return ''
    
    }

    static formatAmount = (amount: number): string => {
            
            return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }

    static formatDateTime = (d: Date): string => {
        
        return d.toLocaleDateString('fr', { year: "numeric", month: "short", day: "numeric" }) + '   ' + d.toLocaleTimeString('fr', { hour: '2-digit', minute: '2-digit' })
        // return d.toLocaleDateString('fr', { year: "numeric", month: "short", day: "numeric" }) + '   ' + d.getHours() + 'h ' + d.getMinutes() + 'mn'
    }

}