import { Area } from "./area.model";

export class Agency {
    id?: number;
    name?: string;
    area?: Area;
    companyId?: number;
    companyName?: string;
    tillsCount?: number;
    createdAt?: Date;
    updatedAt?: Date;

    constructor(args: {id?: number, name?: string, area?: Area, companyId?: number, companyName?: string, tillsCount?: number, createdAt?: Date, updatedAt?: Date}) {
        this.id = args.id
        this.name = args.name
        this.area = {...args.area}
        this.companyId = args.companyId
        this.companyName = args.companyName
        this.tillsCount = args.tillsCount
        this.createdAt = args.createdAt
        this.updatedAt = args.updatedAt
    }

    static fromJSON(jsonData: any): Agency {
        return new Agency({
            id: jsonData.id,
            name: jsonData.name,
            area: Area.fromJSON(jsonData.Area || jsonData.area),
            companyId: jsonData.company_id,
            companyName: jsonData.company_name,
            tillsCount: jsonData.number_of_tills ||0, 
            createdAt: new Date(jsonData.created_at),
            updatedAt: new Date(jsonData.updated_at)
        })
    }
}