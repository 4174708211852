import { useEffect, useState } from 'react'

import { Icon } from 'semantic-ui-react'

import css from './supervisor-dashboard.module.css'

import { HelperService } from '../../services'

export function SupervisorDashboardPage() {

    const [companyName, setCompanyName] = useState<string>('')

    useEffect( () => {

        const name = HelperService.getCompanyName()

        setCompanyName(name)

    }, [])


    return <div>
      <h1>Tableau de bord du superviseur</h1>
      <h3>Contenu à venir</h3>
    </div>
}
