import { MutableRefObject, useState, useRef, ChangeEvent, MouseEvent } from 'react'

import { Button, Modal, Header, Icon, Step, Form, Grid, Message } from 'semantic-ui-react'

import { UploadService, UserService, HelperService } from '../services'

import { DropdownOptions, FeedbackMessage } from '../constants'

import { ErrorMessage } from './error-message'

import { User } from '../models'

import css from './user-create-form.module.css'

export function UserCreateForm({setCreateModalVisible, addUserToList} : {setCreateModalVisible: Function, addUserToList: Function}) {

    const cniRectoImageRef = useRef<any>(null)
    const cniVersoImageRef = useRef<any>(null)

    const [userFirstname, setUserFirstname] = useState('')
    const [userLastname, setUserLastname] = useState('')
    const [userSex, setUserSex] = useState('')
    const [userAddress, setUserAddress] = useState('')
    const [userPhone, setUserPhone] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userNIN, setUserNin] = useState('')
    const [userPassword, setUserPassword] = useState(HelperService.genStrongPassword())
    const [maritalStatus, setMaritalStatus] = useState('')

    const [companyName, setCompanyName] = useState('')
    const [agencyName, setAgencyName] = useState('')
    const [userRole, setUserRole] = useState('')
    const [userAccounStatus, setUserAccountStatus] = useState('')
    const [salary, setSalary] = useState('')

    const [cniRectoBinaryFile, setCniRectoBinaryFile] = useState<File>()
    const [cniVersoBinaryFile, setCniVersoBinaryFile] = useState<File>()
    

    const [userCreationLoading, setUserCreationLoading] = useState(false)
    const [userCreationError, setUserCreationError] = useState(false)


    const [stepValue, setStepValue] = useState<number>(1)


    const companies = HelperService.retrieveCompanies().map(company => {
        return {
            key: company.id,
            text: company.name,
            value: company.name
        }
    })


    const agencies = HelperService.retrieveAgencies().map(agency => {
        return {
            key: agency.id,
            text: agency.name,
            value: agency.name
        }
    })


    const getCompanyId = (cName: string) => companies.find(c => c.text === cName)?.key

    const getAgencyId = (aName: string) => agencies.find(a => a.text === aName)?.key

    const getPermissionId = (pName: string) => DropdownOptions.permissionsLevel.find(p => p.text === pName)?.key


    const nextStep = () => setStepValue((v: number) => v + 1)

    const prevStep = () => setStepValue((v: number) => v - 1)
    

    const handleCniRectoChange = async (e: ChangeEvent<HTMLInputElement>) => {

        const file = HelperService.getFile(e)

        if (file) {

            showPreview(file, cniRectoImageRef)
        }

        setCniRectoBinaryFile(file as File)
    }

    const handleCniVersoChange = async (e: ChangeEvent<HTMLInputElement>) => {

        const file = HelperService.getFile(e)

        if (file) {
            
            showPreview(file, cniVersoImageRef)

        }

        setCniVersoBinaryFile(file as File)
    }



    const showPreview = (file: File, imagePreviewRef: MutableRefObject<any>) => {

        const fileReader = new FileReader()

        fileReader.readAsDataURL(file);

        fileReader.onload = (e: any) => imagePreviewRef.current.src = e.target.result
    }

    const handleFormSubmit = (e: MouseEvent<HTMLButtonElement>) => {
 
        const formValues = getFormValues()

        console.log(formValues)
   
        submitFormValues(formValues)
    }

    const getFormValues = () => {
        return  new User({
            firstname: userFirstname,
            lastname: userLastname,
            sex: userSex,
            address: userAddress,
            phone: userPhone,
            email: userEmail,
            nin: userNIN,
            maritalStatus: maritalStatus,
            salary: Number(salary),
            isActive: userAccounStatus === 'Actif',
            permissions: [getPermissionId(userRole)],
            company: { name: companyName, id: getCompanyId(companyName) },
            agency: { name: agencyName, id: getAgencyId(agencyName) },
            role: userRole,
            password: userPassword
        })
    }

    const submitFormValues = (user: User): void => {

        setUserCreationLoading(true)


        UploadService.uploadFile(cniRectoBinaryFile)

            .then(path1 => user.cniRectoPath = path1)
            .then(_ => UploadService.uploadFile(cniVersoBinaryFile))
            .then(path2 => user.cniVersoPath = path2)
            .then(_ => UserService.createUser(user))
            .then(u => {

                addUserToList(u)

                setCreateModalVisible(false)
            })                
            .catch(_ =>  setUserCreationError(true))
            .finally(() => setUserCreationLoading(false))
    }

    return <Modal
        onClose={() => setCreateModalVisible(false)}
        onOpen={() => setCreateModalVisible(true)}
        open={true}>
        <Modal.Content>
            <Modal.Description>
                <Header>Nouvelle companie</Header>
                { userCreationError && <ErrorMessage errorText={FeedbackMessage.COMPANY_CREATE_ERROR} />}
                <Step.Group widths={3}>
                    <Step active={stepValue === 1}>
                        <Icon name='user' />
                        <Step.Content>
                            <Step.Title>User</Step.Title>
                            <Step.Description>Infos personnelles</Step.Description>
                        </Step.Content>
                    </Step>

                    <Step active={stepValue === 2}>
                        <Icon name='cog' />
                        <Step.Content>
                            <Step.Title>Configuration</Step.Title>
                            <Step.Description>configs détails</Step.Description>
                        </Step.Content>
                    </Step>

                    <Step active={stepValue === 3}>
                        <Icon name='credit card' />
                        <Step.Content>
                            <Step.Title>Pièces</Step.Title>
                            <Step.Description>CNI du users</Step.Description>
                        </Step.Content>
                    </Step>
                </Step.Group>

                <Form error={false}>
                    <Message
                        error
                        header='Action Forbidden'
                        content='You can only sign up for an account once with a given e-mail address.' />
                    
                  
                    {
                        stepValue === 1 && <div>
                            <Form.Group widths='equal'>
                                <Form.Input label='First name' placeholder='First name' value={userFirstname} onChange={e => setUserFirstname(e.target.value)} />
                                <Form.Input fluid label='Last name' placeholder='Last name' value={userLastname} onChange={e => setUserLastname(e.target.value)} />
                                <Form.Select fluid label='Sexe' options={DropdownOptions.sexOptions} placeholder='homme ou femme' value={userSex} onChange={(e: any) => setUserSex(e.target.textContent)} />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input type='text' fluid label='Adresse' placeholder='Adresse' value={userAddress} onChange={e => setUserAddress(e.target.value)} />
                                <Form.Input type='phone' fluid label='Téléphone' placeholder='Téléphone' value={userPhone} onChange={e => setUserPhone(e.target.value)} />
                                <Form.Input type='email' fluid label='Email' placeholder='Email' value={userEmail} onChange={e => setUserEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input fluid label='NIN' placeholder='Numéro pièce' value={userNIN} onChange={e => setUserNin(e.target.value)} />
                                <Form.Input fluid label='Password' placeholder='Password' value={userPassword} onChange={e => setUserPassword(e.target.value)} />
                                <Form.Select fluid label='Statut matrimonial' options={DropdownOptions.maritalStatusOptions} placeholder='Statut' value={maritalStatus} onChange={(e: any) => setMaritalStatus(e.target.textContent)} />
                            </Form.Group>
                        </div>
                    }

                    {
                        stepValue === 2 && <div>
                            <Form.Group widths='equal'>
                                <Form.Select fluid label='Compagnie' options={companies} placeholder='' value={companyName} onChange={(e: any) => setCompanyName(e.target.textContent)} />
                                <Form.Select fluid label='Agence' options={agencies} placeholder='' value={agencyName} onChange={(e: any) => setAgencyName(e.target.textContent)} />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Select fluid label='Statut' options={DropdownOptions.accountStatus} placeholder='statut du compte' value={userAccounStatus} onChange={(e: any) => setUserAccountStatus(e.target.textContent)} />
                                <Form.Select fluid label='Role' options={DropdownOptions.permissionsLevel} placeholder='role user' value={userRole} onChange={(e: any) => setUserRole(e.target.textContent)} />
                            </Form.Group>
                            <Form.Input type='number' fluid label='Salaire' placeholder='Salaire du user (optionnelle)' value={salary} onChange={e => setSalary(e.target.value)} />
                        </div>
                    }

                    {
                        stepValue === 3 && <div>
                            <br />
                            <Grid columns={2} divided centered>
                                <Grid.Row centered>
                                    <Grid.Column className={css.centeredContent}>
                                        <label htmlFor='cni_recto' className={css.inputLabel}>
                                            <img ref={cniRectoImageRef} src='/images/placeholder.png' className={css.cniPreview} alt='' />
                                        </label>
                                        <input type='file' id='cni_recto' onChange={handleCniRectoChange} />
                                        <h3>CNI recto</h3>
                                    </Grid.Column>
                                    <Grid.Column className={css.centeredContent}>
                                        <label htmlFor='cni_verso' className={css.inputLabel}>
                                            <img ref={cniVersoImageRef} src='/images/placeholder.png' className={css.cniPreview} alt='' />
                                        </label>
                                        <input type='file' id='cni_verso' onChange={handleCniVersoChange} />
                                        <h3>CNI verso</h3>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    }
                </Form>

            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            {stepValue > 1 && <Button color='black' onClick={prevStep}>Précédent</Button>}
            {stepValue < 3 && <Button color='orange' onClick={nextStep}>Suivant</Button>}
            {stepValue === 3 && <Button color='orange' loading={userCreationLoading} content='Sauvegarder' type='submit' onClick={handleFormSubmit} />}
        </Modal.Actions>
    </Modal>
}