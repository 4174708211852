import { useState } from 'react'
import { Button, Form, Header, Modal } from 'semantic-ui-react'

import { HelperService, AgencyService } from '../services'

import { FeedbackMessage } from '../constants'

import { ErrorMessage } from './error-message'

import { Agency, Area } from '../models'


export function AgencyCreateForm({setCreateModalVisible, addAgencyToList}: {setCreateModalVisible: Function, addAgencyToList: Function}) {

    const [creating, setCreating] = useState(false)
    const [agencyArea, setAgencyArea] = useState<string>('')
    const [agencyName, setAgencyName] = useState<string>('')
    const [companyName, setCompanyName] = useState<string>('')
    const [areaDescription, setAreaDescription] = useState<string>('')
    const [agencyCreationError, setAgencyCreationError] = useState(false)


    const areas = HelperService.retrieveAreas().map(area => {
        return {
            key: area.id,
            text: area.name,
            value: area.name
        }
    })


    const companies = HelperService.retrieveCompanies().map(company => {
        return {
            key: company.id,
            text: company.name,
            value: company.name
        }
    })

    const getAreaId = (aName: string) => areas.find(a => a.text === aName)?.key

    const getCompanyId = (cName: string) => companies.find(c => c.text === cName)?.key


    const handleFormSubmit = () => {

        setCreating(true)

        const agency = new Agency({name: agencyName, companyId: getCompanyId(companyName),  area: new Area({id: getAreaId(agencyArea)}) })

        AgencyService.createAgency(agency)
            .then(agency => {
                addAgencyToList(agency)
                setCreateModalVisible(false)
            })
            .catch(__ => setAgencyCreationError(true))
            .finally(() => setCreating(false))
    }
    
    return <Modal
        size='tiny'
        onClose={() => setCreateModalVisible(false)}
        onOpen={() => setCreateModalVisible(true)}
        open={true}>
        <Modal.Content >
            <Modal.Description>
                <Header as="h3">Nouvelle agence</Header>
                { agencyCreationError && <ErrorMessage errorText={FeedbackMessage.AGENCY_CREATE_ERROR} />}
                <br/>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Select
                            fluid
                            label='Zones'
                            options={areas}
                            placeholder='Choisir une zone'
                            value={agencyArea}
                            onChange={(e: any) => setAgencyArea(e.target.textContent)}
                        />

                        <Form.Input 
                        fluid 
                        label='Nom' 
                        placeholder="Nom de l'agence"
                        value={agencyName}
                        onChange={(e: any) => setAgencyName(e.target.value)} />
                    </Form.Group>
                    <Form.Select 
                        fluid 
                        label='Compagnie' 
                        options={companies}
                        placeholder="Compagnie associée"
                        value={companyName}
                        onChange={(e: any) => setCompanyName(e.target.textContent)} />
                    <Form.TextArea 
                        label='Description' 
                        value={areaDescription}
                        onChange={(e: any) => setAreaDescription(e.target.value)}
                        placeholder="Description de l'agence " />
                </Form>
                <br/>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => setCreateModalVisible(false)}>Annuler</Button>
            <Button color='orange' content="Confirmer" onClick={handleFormSubmit} loading={creating}/>
        </Modal.Actions>
    </Modal>
}