import { useEffect, useState } from "react"

import { Button, Icon, Label } from "semantic-ui-react"

import { Till } from "../../models/till.model"

import { TillService, HelperService } from "../../services"

import { TillsList, TillCreateForm, LoadingSpinner, ErrorMessage } from "../../components"

import { FeedbackMessage } from "../../constants"

export function TillsPage() {

    const [tills, setTills] = useState<Till[]>([])
    const [userRole, setUserRole] = useState<string>('')
    const [loadingTills, setLoadingTills] = useState(true)
    const [errorFetchingTills, setErrorFetchingTills] = useState(false)
    const [createModalVisible, setCreateModalVisible] = useState(false)

    useEffect(() => {

        setUserRole(HelperService.getRoleFromToken())

        TillService.getTills()
            .then(data => setTills(data))
            .catch(_ => setErrorFetchingTills(true))
            .finally(() => setLoadingTills(false))
    }, [])


    const addToList = (till: Till) => setTills([...tills, till])

    return <div>
        { loadingTills && <LoadingSpinner />}
        { errorFetchingTills && <ErrorMessage errorText={FeedbackMessage.TILL_FETCH_ERROR} />}
        { !loadingTills && !errorFetchingTills &&
            <div style={{width: '1150px', margin: 'auto'}}>
                <div className='headerRow'>
                        <div className='flex-center'>
                            <h3 className='mb-0 mr-25'><Label color='orange'>{tills.length}</Label> &nbsp; Caisses</h3>
                        </div>
                        { userRole ==='Admin' && 
                            <Button color="orange" className="size-16" onClick={() => setCreateModalVisible(true)}>
                                <Icon name="add circle" />Nouvelle caisse
                            </Button> 
                        }
                    </div>
                <TillsList tills={tills} userRole={userRole}/>
            </div>
        }

        { createModalVisible && <TillCreateForm setCreateModalVisible={setCreateModalVisible} addTillToList={addToList}/> }
    </div>

}