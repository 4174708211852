export class Area {
    id?: number;
    name?: string;
    description?: string;
    region?: string;
    department?: string;
    agenciesCount?: number;
    tillsCount?: number;
    usersCount?: number;
    createdAt?: Date;
    updatedAt?: Date;

    constructor(args:{id?: number, name?: string, description?: string, region?: string, department?: string, agenciesCount?: number, tillsCount?: number, usersCount?: number, createdAt?: Date, updatedAt?: Date}) {
        this.id = args.id
        this.name = args.name
        this.description = args.description
        this.region = args.region
        this.department = args.department
        this.agenciesCount = args.agenciesCount
        this.tillsCount = args.tillsCount
        this.usersCount = args.usersCount
        this.createdAt = args.createdAt
        this.updatedAt = args.updatedAt
    }

    static fromJSON(jsonData: any): Area {

        if(!jsonData) return new Area({})
            
        return new Area({
            id: jsonData.id,
            name: jsonData.name,
            description: jsonData.description,
            region: jsonData.region,
            department: jsonData.department,
            agenciesCount: jsonData.number_of_agencies ||0,
            tillsCount: jsonData.number_of_tills ||0 ,
            usersCount: jsonData.number_of_users ||0,
            createdAt: new Date(jsonData.created_at),
            updatedAt: new Date(jsonData.updated_at)
        })
    }

    static toJSON(area: Area): any {
        return {
            name: area.name,
            description: area.description,
            region: area.region,
            department: area.department
        }
    }
}