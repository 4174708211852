import { getAxiosInstance } from "../http"

import { User } from "../models"

export class UserService {

    static getUsersList = async () => {

        const instance = getAxiosInstance()

        const response = await instance.get('users')

        return response.data.map((u: any) => User.fromJSON(u))
    }

    static createUser = async (user: User) => {
            
            const instance = getAxiosInstance()
    
            const response = await instance.post('users', user.toJSON())
    
            return User.fromJSON(response.data)
    }
}