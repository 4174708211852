import { useEffect, useState } from 'react'
import { Icon, Form, Button, FormField, Radio } from 'semantic-ui-react'
import { CashReceptionService, MobileMoneyServiceService, PartnerService } from '../services'
import { CashReception, MobileMoney, Partner } from '../models'
import { ErrorMessage } from './error-message'
import { FeedbackMessage } from '../constants'

const inlineStyle = {
    wrapper: {
        padding: '20px',
        borderRadius: '5px',
        backgroundColor: '#f9fafb',
        minHeight: '240px',
        border: '1px solid #d4d4d5',
    },
    step: {
        display: 'flex',
        justifyContent: 'center',
        height: '160px',
        paddingTop: '15px',
    }
}

export function DistributorCashReceptionForm({addToList} : {addToList: Function}) {

    const [step, setStep] = useState(1)
    const [source, setSource] = useState('')
    const [amount, setAmount] = useState('')
    const [sourceId, setSourceId] = useState('')
    const [comments, setComments] = useState('')
    const [creating, setCreating] = useState(false)
    const [error, setError] = useState(false)
    const [partners, setPartners] = useState<Partner[]>([])
    const [mobileMoneyServices, setMobileMoneyServices] = useState<MobileMoney[]>([])


    useEffect(() => {

        getMobileMoneyServiceList()

        getPartnersList()

    }, [])


    const sourceOptions = [
        { key: 'b', text: 'Banque', value: 'bank' },
        { key: 'p', text: 'Partenaire', value: 'partner' },
    ]

    const handleNext = () => {

        if (step === 1 && source === '') {

            alert('Veuillez choisir l\'origine des fonds')

            return
        }

        setStep(step => step + 1)
    }

    const handlePartnerChange = (e: any, data: any) => setSourceId(data.value)

    const handleServiceChange = (e: any, data: any) => setSourceId(data.value)


    const getMobileMoneyServiceList = () => {

        MobileMoneyServiceService.getMobileMoneyServices()
            .then(data => setMobileMoneyServices(data))
            .catch(err => setError(true))
            .finally(() => { })
    }

    const getPartnersList = () => {

        PartnerService.getPartners()
            .then(data => setPartners(data))
            .catch(err => setError(true))
            .finally(() => { })
    }

    const handleFormSubmit = () => {

        setCreating(true)

        const cr = new CashReception({
            sourceType: source,
            sourceId: parseInt(sourceId),
            amount: parseInt(amount),
            comments: comments
        })

        CashReceptionService.createCashReception(cr)
            .then(data => {
                reinitForm()
                addToList(data)
            })
            .catch(err => setError(true))
            .finally(() => setCreating(false))
    }

    const reinitForm = () => {
        setStep(1)
        setSource('')
        setAmount('')
        setComments('')
    }

    return <div style={inlineStyle.wrapper}>

        {error && <ErrorMessage errorText={FeedbackMessage.CASH_RECEPTION_CREATE_ERROR} />}

        <Form>
            {step === 1 &&
                <div style={inlineStyle.step}>
                    <div style={{ width: '400px' }}>
                        <h3>Choisir l'origine des fonds</h3>

                        <FormField>
                            <Radio
                                label='Un service de mobile money'
                                name='radioGroup'
                                value='service'
                                checked={source === 'service'}
                                onChange={_ => setSource('service')}
                            />
                        </FormField>
                        <br />
                        <FormField>
                            <Radio
                                label='Un partenaire de la société'
                                name='radioGroup'
                                value='partner'
                                checked={source === 'partner'}
                                onChange={_ => setSource('partner')}
                            />
                        </FormField>
                    </div>
                </div>
            }

            {step === 2 &&
                <div style={inlineStyle.step}>
                    <div style={{ width: '400px' }}>

                        <h3>Pourvoyeur des fonds</h3>
                        {
                            source === 'partner' &&
                            <Form.Select
                                fluid
                                options={partners.map(p => ({ key: p.id, text: p.name, value: p.id }))}
                                placeholder='Choisir le partenaire'
                                value={sourceId}
                                onChange={handlePartnerChange}
                            />
                        }

                        {
                            source === 'service' &&
                            <Form.Select
                                fluid
                                options={mobileMoneyServices.map(m => ({ key: m.id, text: m.name + (m.bank ? ' --> ' : '') + m.bank, value: m.id }))}
                                placeholder='Choisir le service de mobile money'
                                value={sourceId}
                                onChange={handleServiceChange}
                            />
                        }
                    </div>
                </div>
            }

            {step === 3 &&
                <div style={inlineStyle.step}>
                    <div style={{ width: '400px' }}>
                        <h3>Montant des fonds (cfa)</h3>
                        <Form.Input
                            fluid
                            options={sourceOptions}
                            placeholder='montant reçu'
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                        />

                        <Form.TextArea
                            value={comments}
                            rows={3}
                            placeholder='Commentsaire optionnel'
                            onChange={e => setComments(e.target.value)}
                        />
                    </div>
                </div>
            }

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {step < 3 && <Button color='black' onClick={handleNext}> Suivant <Icon name='long arrow alternate right' /></Button>}
                {step === 3 && <Button color='black' content="Confirmer" onClick={handleFormSubmit} loading={creating} />}
            </div>
        </Form>
    </div>
}
