import { getAxiosInstance } from "../http";

import { Till } from "../models/till.model"

export class TillService {

    static getTills = async (): Promise<Till[]> => {

        const instance = getAxiosInstance()

        const response = await instance.get('tills')

        return response.data.map((t: any) => Till.fromJSON(t))
    }

    static createTill = async (till: { companyId: number, agencyId: number, userId: number, serviceIds: number[], }): Promise<Till> => {

        const instance = getAxiosInstance()

        const payload = { company_id: till.companyId, agency_id: till.agencyId, user_id: till.userId, services_id: till.serviceIds }

        const response = await instance.post('tills', JSON.stringify(payload))

        return Till.fromJSON(response.data)
    }
}