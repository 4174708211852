export const DropdownOptions = {

    sexOptions: [
        { key: 'm', text: 'Homme', value: 'Homme' },
        { key: 'f', text: 'Femme', value: 'Femme' }
    ],

    accountStatus : [
        { key: 'a', text: 'Actif', value: 'Actif' },
        { key: 'i', text: 'Inactif', value: 'Inactif' }
    ],

    permissionsLevel: [
        // { key: 1, text: 'admin_sys', value: 'admin_sys' },
        // { key: 2, text: 'company_supervisor', value: 'company_supervisor' },
        { key: 3, text: 'company_general_distributor', value: 'company_general_distributor' },
        { key: 4, text: 'company_local_distributor', value: 'company_local_distributor' },
        { key: 5, text: 'company_cashier', value: 'company_cashier' },
    ],
    maritalStatusOptions: [
        { key: 'm', text: 'Marié(e)', value: 'Marié(e)' },
        { key: 's', text: 'Célibataire', value: 'Célibataire' },
        { key: 'd', text: 'Divorcé(e)', value: 'Divorcé(e)' },
    ],
    regionOptions: [
        { key: 'tamba', value: 'Tambacounda', text: 'Tambacounda' },
        { key: 'dakar', value: 'Dakar', text: 'Dakar' },
        { key: 'diourbel', value: 'Diourbel', text: 'Diourbel' },
        { key: 'thies', value: 'Thies', text: 'Thies' },
        { key: 'kaolack', value: 'Kaolack', text: 'Kaolack' },
        { key: 'sedhiou', value: 'Sédhiou', text: 'Sédhiou' },
        { key: 'kedougou', value: 'Kédougou', text: 'Kédougou' },
        { key: 'fatick', value: 'Fatick', text: 'Fatick' },
        { key: 'kaffrine', value: 'Kaffrine', text: 'Kaffrine' },
        { key: 'matam', value: 'Matam', text: 'Matam' },
        { key: 'louga', value: 'Louga', text: 'Louga' },
        { key: 'saintlouis', value: 'Saint-Louis', text: 'Saint-Louis' },
        { key: 'kolda', value: 'Kolda', text: 'Kolda' },
        { key: 'zig', value: 'Ziguinchor', text: 'Ziguinchor' },
    ],
    departementsOptions: [
        { key: 'dakar', value: 'Dakar', text: 'Dakar' },
        { key: 'guediawaye', value: 'Guédiawaye', text: 'Guédiawaye' },
        { key: 'pikine', value: 'Pikine', text: 'Pikine' },
        { key: 'rufisque', value: 'Rufisque', text: 'Rufisque' },
        { key: 'bambey', value: 'Bambey', text: 'Bambey' },
        { key: 'diourbel', value: 'Diourbel', text: 'Diourbel' },
        { key: 'mbacke', value: 'Mbacké', text: 'Mbacké' },
        { key: 'fatick', value: 'Fatick', text: 'Fatick' },
        { key: 'foundiougne', value: 'Foundiougne', text: 'Foundiougne' },
        { key: 'gossas', value: 'Gossas', text: 'Gossas' },
        { key: 'kaolack', value: 'Kaolack', text: 'Kaolack' },
        { key: 'guinguineo', value: 'Guinguinéo', text: 'Guinguinéo' },
        { key: 'niorodurip', value: 'Nioro du Rip', text: 'Nioro du Rip' },
        { key: 'kolda', value: 'Kolda', text: 'Kolda' },
        { key: 'velingara', value: 'velingara', text: 'Vélingara' },
        { key: 'medinayorofoulah', value: 'Médina Yoro Foulah', text: 'Médina Yoro Foulah' },
        { key: 'kebemer', value: 'Kébémer', text: 'Kébémer' },
        { key: 'kanel', value: 'Kanel', text: 'Kanel' },
        { key: 'matam', value: 'Matam', text: 'Matam' },
        { key: 'ranerou', value: 'Ranérou', text: 'Ranérou' },
        { key: 'dagana', value: 'Dagana', text: 'Dagana' },
        { key: 'podor', value: 'Podor', text: 'Podor' },
        { key: 'saintlouis', value: 'Saint-Louis', text: 'Saint-Louis' },
        { key: 'bakel', value: 'Bakel', text: 'Bakel' },
        { key: 'koumpentoum', value: 'Koumpentoum', text: 'Koumpentoum' },
        { key: 'tambacounda', value: 'Tambacounda', text: 'Tambacounda' },
        { key: 'goudiry', value: 'Goudiry', text: 'Goudiry' },
        { key: 'mbour', value: 'Mbour', text: 'Mbour' },
        { key: 'thies', value: 'Thiès', text: 'Thiès' },
        { key: 'tivaouane', value: 'Tivaouane', text: 'Tivaouane' },
        { key: 'bignona', value: 'Bignona', text: 'Bignona' },
        { key: 'oussouye', value: 'Oussouye', text: 'Oussouye' },
        { key: 'ziguinchor', value: 'Ziguinchor', text: 'Ziguinchor' },
        { key: 'mbirkilane', value: 'MBirkilane', text: 'MBirkilane' },
        { key: 'kaffrine', value: 'Kaffrine', text: 'Kaffrine' },
        { key: 'malemhodar', value: 'Malem-Hodar', text: 'Malem-Hodar' },
        { key: 'kounghel', value: 'Kounghel', text: 'Kounghel' },
        { key: 'kedougou', value: 'Kédougou', text: 'Kédougou' },
        { key: 'salemata', value: 'Salemata', text: 'Salemata' },
        { key: 'bounkiling', value: 'Bounkiling', text: 'Bounkiling' },
        { key: 'goudomp', value: 'Goudomp', text: 'Goudomp' },
        { key: 'sedhiou', value: 'Sédhiou', text: 'Sédhiou' }
    ],

    areaOptions: [
        { key: 'mbacke', value: 'mbacke', text: 'Mbacké' },
        { key: 'touba', value: 'touba', text: 'Touba' },
        { key: 'thiadiaye', value: 'thiadiaye', text: 'Thiadiaye' }
    ],

    agenciesOptions: [
        { key: 'khayra', value: 'khayra', text: 'Agence khayra' },
        { key: 'darouMinane', value: 'darouMinane', text: 'Agence Darou Minane' },
        { key: 'ngueweul', value: 'nguéweul', text: 'Agence Nguéweul' },
        { key: 'tableauThiabi', value: 'tableauThiabi', text: 'Agence tableau thiabi' },
        { key: 'ndoyeene', value: 'ndoyeene', text: 'Agence ndoyeene' },
    ],

    senegalBanksDropDown: [

        { key: 6, value: "Banque Atlantique Sénégal", text: "Banque Atlantique Sénégal" },
        { key: 16, value: "Banque Agricole", text: "Banque Agricole" },
        { key: 7, value: "Banque Islamique du Sénégal (BIS)", text: "Banque Islamique du Sénégal (BIS)" },
        { key: 17, value: "Banque de Dakar (BDK)", text: "Banque de Dakar (BDK)" },
        { key: 1, value: "Banque de l'Habitat du Sénégal (BHS)", text: "Banque de l'Habitat du Sénégal (BHS)" },
        { key: 10, value: "Banque Nationale pour le Développement Économique (BNDE)", text: "Banque Nationale pour le Développement Économique (BNDE)" },
        { key: 18, value: "Banque Populaire de l’Epargne et du Crédit (BPEC)", text: "Banque Populaire de l’Epargne et du Crédit (BPEC)" },
        { key: 2, value: "Banque Internationale pour le Commerce et l'Industrie du Sénégal (BICIS)", text: "Banque Internationale pour le Commerce et l'Industrie du Sénégal (BICIS)" },
        { key: 8, value: "Banque Régionale de Marchés (BRM)", text: "Banque Régionale de Marchés (BRM)" },
        { key: 11, value: "CBAO Groupe Attijariwafa Bank", text: "CBAO Groupe Attijariwafa Bank" },
        { key: 4, value: "Caisse Nationale de Crédit Agricole du Sénégal (CNCAS)", text: "Caisse Nationale de Crédit Agricole du Sénégal (CNCAS)" },
        { key: 14, value: "Citibank Sénégal", text: "Citibank Sénégal" },
        { key: 9, value: "Coris Bank International Sénégal", text: "Coris Bank International Sénégal" },
        { key: 5, value: "Ecobank Sénégal", text: "Ecobank Sénégal" },
        { key: 15, value: "La Banque Outarde", text: "La Banque Outarde" },
        { key: 12, value: "Orabank Sénégal", text: "Orabank Sénégal" },
        { key: 3, value: "Société Générale de Banques au Sénégal (SGBS)", text: "Société Générale de Banques au Sénégal (SGBS)" },
        { key: 19, value: "Banque Sahelo-Saharienne pour l'Investissement et le Commerce (BSIC)", text: "Banque Sahelo-Saharienne pour l'Investissement et le Commerce (BSIC)" },
        { key: 20, value: "Bridge Bank Group Senegal", text: "Bridge Bank Group Senegal" },
        { key: 13, value: "United Bank for Africa (UBA) Sénégal", text: "United Bank for Africa (UBA) Sénégal" }
    ],

    mobileMoneyOptions: [
            { key: 1, value: 'Wave', text: 'Wave' },
            { key: 2, value: 'Orange Money', text: 'Orange Money' },
            { key: 3, value: 'Free Money', text: 'Free Money' },
            { key: 4, value: 'Ria Money', text: 'Ria Money' },
            { key: 5, value: 'Western Union', text: 'Western Union' },
            { key: 6, value: 'Money Gram', text: 'Money Gram' },
            { key: 7, value: 'Kpay', text: 'Kpay' },
            { key: 8, value: 'Kash-Kash', text: 'Kash-Kash' },
            { key: 9, value: 'Small World', text: 'Small World' },
    ],

    mobileMoneyTypes: [
        { key: 1, value: 'national', text: 'national' },
        { key: 2, value: 'international', text: 'international' }
    ]
}