import { Button, Icon } from 'semantic-ui-react'

import { Agency } from '../models'

import css from './agency-item.module.css'



export function AgencyItem({ agency, showDeletePopup, showEditForm, userRole }: { agency: Agency, showDeletePopup: Function, showEditForm: Function, userRole: string }) {

    return <div className={css.agencyItem}>

        {
            ['Admin', 'Superviseur'].includes(userRole)

            &&

            <div>
                <h3 className='mb-8'>#{agency.id}</h3>
                <p>ID</p>
            </div>
        }

        {
            ['Admin', 'Superviseur'].includes(userRole) &&
            <div className={css.agencyName}>
                <h3 className='mb-8'>{agency.name}</h3>
                { userRole === 'Admin'  && <p>Compagnie: {agency.companyName}</p>}
            </div>
        }

        {
            userRole === 'Admin' &&
            <div className={css.agencyArea}>
                <h3 className='mb-8'>Zone</h3>
                <p>{agency.area?.name}</p>
            </div>
        }

        {
            userRole === 'Admin' &&
            <div className={css.agencyDept}>
                <h3 className='mb-8'>Dépt.</h3>
                <p>{agency.area?.department}</p>
            </div>
        }
        <div className={css.agencyTills}>
            <h3 className='mb-8'>{agency.tillsCount}</h3>
            <p>Caisses</p>
        </div>
        <div className={css.actions}>
            {userRole === 'Admin' &&<Button icon className='mr-10' size='large' onClick={() => showDeletePopup(agency.id)}><Icon name='trash alternate' /></Button>}
            <Button icon className='mr-10' size='large'> <Icon name='eye' /></Button>
            {userRole === 'Admin' && <Button icon size='large' onClick={() => showEditForm(agency)}><Icon name='pencil alternate' /></Button>}
        </div>
    </div>
}