import { getAxiosInstance } from "../http"

interface Credentials {
  username: string,
  password: string
}

export class AuthService {

  static attemptLogin = async ({ username, password }: Credentials): Promise<string> => {

    const devAuthUrl = 'http://localhost:8000/auth'

    const prodAuthUrl = 'https://transfert.ligueye.com/auth/'
    
    const isProd = window.location.hostname === 'transfert.ligueye.com' 
    
    const authUrl = isProd ? prodAuthUrl : devAuthUrl

    const instance = getAxiosInstance(authUrl)

    const payload = new FormData()

    payload.append('username', username)

    payload.append('password', password)

    const headers = { headers: { "Content-Type": 'application/x-www-form-urlencoded' } }

    const response = await instance.post('login', payload, headers)

    return response.data?.access_token
  }


  static getRouteFromScope = (decoded: any): string => {

    const companyName = decoded.company_name

    localStorage.setItem('okdev__companyName', companyName)

    const scopes = decoded.scopes

    if (scopes.includes('admin_sys'))
      return 'admin_sys'

    if (scopes.includes('company_supervisor'))
      return `${companyName}/supervisor`

    if(scopes.includes('company_local_distributor')) {
      return `${companyName}/distributor`
    }

    if (scopes.includes('company_cashier'))
      return `${companyName}/cashier`

    return ''
  }

}