import css from './not-found.module.css'

export function NotFoundPage() {
    return <section className={css.container}>
        <div className={css.content}>
       
            <h1 className={css.title}>Page introuvable !</h1>
            <img src='/images/404.jpeg' width={500} alt='404'/>
        </div>
    </section>
}