import { useEffect, useState } from "react"
import { Button, Icon, Label } from "semantic-ui-react"


import { Partner} from "../../models"
import { HelperService, PartnerService } from "../../services"
import { FeedbackMessage } from "../../constants"
import { PartnerList, PartnerCreateForm, ErrorMessage, LoadingSpinner } from "../../components"


export function DistributorPartners() {

    const [userRole, setUserRole] = useState<string>('')
    const [partners, setPartners] = useState<Partner[]>([])
    const [loadingPartners, setLoadingPartners] = useState(true)
    const [errorFetchingPartners, setErrorFetchingPartners] = useState(false)
    const [createModalVisible, setCreateModalVisible] = useState(false)

    const addToList = (partner: Partner) =>  setPartners([...partners, partner])

    useEffect(() => {
        
        setUserRole(HelperService.getRoleFromToken())
        
        PartnerService.getPartners()
            .then(data => setPartners(data))
            .catch(err => setErrorFetchingPartners(true))
            .finally(() => setLoadingPartners(false))
    }, [])


    return <div>
       
        { loadingPartners && <LoadingSpinner />}
        { errorFetchingPartners && <ErrorMessage errorText={FeedbackMessage.PARTNER_FETCH_ERROR} />}
        { !loadingPartners && !errorFetchingPartners &&
            <div style={{width: '1200px', margin: 'auto'}}>
                <div className='headerRow'>
                        <div className='flex-center'>
                            <h3 className='mb-0 mr-25'><Label color='orange'>{partners.length}</Label> &nbsp; Partenaires</h3>
                        </div>
                        <Button color='orange' className='size-16' onClick={() => setCreateModalVisible(true)}>
                            <Icon name="add circle" />Nouveau partenaire
                        </Button>
                    </div>
                <PartnerList partners={partners} userRole={userRole} />
            </div>
        }

        { createModalVisible && <PartnerCreateForm setCreateModalVisible={setCreateModalVisible} addPartnerToList={addToList} userRole={userRole}/> }
    </div>
}