import { Button, Icon, Table } from 'semantic-ui-react'

import { Till } from "../models"

import { NoData } from "./no-data";

export function TillsList({ tills, userRole }: { tills: Till[], userRole: string }) {

    return <div>
        {
            tills.length === 0 ?  <NoData/> :

            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className="size-17">ID</Table.HeaderCell>
                        { userRole ==='Admin' && <Table.HeaderCell className="size-17">Compagnie</Table.HeaderCell> }
                        <Table.HeaderCell className="size-17">Agence</Table.HeaderCell>
                        <Table.HeaderCell className="size-17"> { userRole ==='Admin' ? 'User' : 'Caissier' } </Table.HeaderCell>
                        <Table.HeaderCell className="size-17">Services</Table.HeaderCell>
                        <Table.HeaderCell className="size-17">Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {tills.map(t => <Table.Row key={t.id}>
                        <Table.Cell>{t.id}</Table.Cell>
                        { userRole ==='Admin' && <Table.Cell>{t.company?.name}</Table.Cell> }
                        <Table.Cell>{t.agency?.name}</Table.Cell>
                        <Table.Cell>{t.user?.firstname + ' ' + t.user?.lastname}</Table.Cell>
                        <Table.Cell>{t.services?.map(s => <p key={s.id}> <strong className='orange'>{s.name}</strong> { ' ⟹ ' + s.bank} </p>)}</Table.Cell>
                        <Table.Cell>
                            <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                            <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                        </Table.Cell>
                    </Table.Row>)}
                </Table.Body>
            </Table>
        }
    </div>

}