import { getAxiosInstance } from "../http";

import { MobileMoney } from "../models/mobile-money.model"

export class MobileMoneyServiceService {

    static getMobileMoneyServices = async (): Promise<MobileMoney[]> => {
  
        const instance = getAxiosInstance()

        const response = await instance.get('services')

        const mm = response.data.map((s: any) => MobileMoney.fromJSON(s))

        return mm.sort((s1: MobileMoney, s2: MobileMoney) => s1.name?.localeCompare(s2.name!) )
    }


    static createService = async (service: MobileMoney): Promise<MobileMoney> => {

        const instance = getAxiosInstance()

        const payload = {name: service.name,  bank: service.bank, service_type: service.type}

        const response = await instance.post('services', JSON.stringify(payload))

        return MobileMoney.fromJSON(response.data)
    }

}