import { getAxiosInstance } from "../http";

import { Agency } from "../models";

export class AgencyService {

    static getAgenciesList = async (): Promise<Agency[]> => {

        const instance = getAxiosInstance()

        const response = await instance.get('agencies')

        try {
            return response.data.map((a: any) => Agency.fromJSON(a))
        }
        catch (e) {
            console.log('error', e)
            return []
        }
    }

    static createAgency = async (agency: Agency): Promise<Agency> => {
            
            const instance = getAxiosInstance()

            const payload = {  name: agency.name, company_id: agency.companyId , area_id: agency.area?.id }
    
            const response = await instance.post('agencies', JSON.stringify(payload))

            return Agency.fromJSON(response.data)
        }
}