import { Button, Icon } from 'semantic-ui-react'

import css from './area-item.module.css'

import { Area } from '../models'

export function AreaItem({ id, name, description, agenciesCount, usersCount, tillsCount, region, department }: Partial<Area>) {
    return <div className={css.areaItem}>
        <div>
            <h3 className='mb-8'>#{id}</h3>
            <p>ID</p>
        </div>
        <div className={css.areaName}>
            <h3 className='mb-8'>{name}</h3>
            <p>{description}</p>
        </div>
        <div className={css.areaAgencies}>
            <h3 className='mb-8'>{agenciesCount}</h3>
            <p>Agences</p>
        </div>
        <div className={css.areaTills}>
            <h3 className='mb-8'>{tillsCount}</h3>
            <p>Caisses</p>
        </div>
        <div className={css.areaRegion}>
            <h3 className='mb-8'>{department}</h3>
            <p>{region}</p>
        </div>
        <div className={css.actions}>
            <Button icon className='mr-10' size='large'> <Icon name='trash alternate' /></Button>
            <Button icon className='mr-10' size='large'> <Icon name='eye'/></Button>
            <Button icon className='mr-10' size='large' ><Icon name='pencil alternate'/></Button>
        </div>
    </div>
}