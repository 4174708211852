import { useState } from 'react'
import { Button, Header, Modal, Form } from 'semantic-ui-react'
import { DropdownOptions, FeedbackMessage } from '../constants'

import { Area } from '../models'
import { AreaService } from '../services'
import { ErrorMessage } from './error-message'

export function AreaCreateForm({setCreateModalVisible, addAreaToList}: {setCreateModalVisible:  Function, addAreaToList: Function}) {

    const [areaRegion, setAreaRegion] = useState('')
    const [areaDepartement, setAreaDepartment] = useState('')
    const [areaName, setAreaName] = useState('')
    const [areaDescription, setAreaDescription] = useState('')
    const [areaCreationError, setAreaCreationError] = useState(false)

    const [creatingArea, setCreatingArea] = useState(false)

    const handleFormSubmit = () => {
       
        const area = new Area({name: areaName, region: areaRegion, department: areaDepartement, description: areaDescription})

        setCreatingArea(true)
  
        AreaService.createArea(area)
            .then(area => {
                addAreaToList(area)
                setCreateModalVisible(false)
            })
            .catch(err => setAreaCreationError(true))
            .finally(() => setCreatingArea(false))
    }


    return <Modal
        size='tiny'
        onClose={() => setCreateModalVisible(false)}
        onOpen={() => setCreateModalVisible(true)}
        open={true}>
        <Modal.Content >
            <Modal.Description>
                <Header as="h3">Nouvelle zone</Header>
                { areaCreationError && <ErrorMessage errorText={FeedbackMessage.AREA_CREATE_ERROR} />}
                <br/>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Select
                            fluid
                            label='Régions'
                            options={DropdownOptions.regionOptions}
                            placeholder='Choisir'
                            value={areaRegion}
                            onChange={(e: any) => setAreaRegion(e.target.textContent)}
                        />
                        <Form.Select
                            fluid
                            label='Départements'
                            options={DropdownOptions.departementsOptions}
                            placeholder='Choisir'
                            value={areaDepartement}
                            onChange={(e: any) => setAreaDepartment(e.target.textContent)}/>
                        <Form.Input 
                            fluid 
                            label='Nom' 
                            placeholder='Nom de la zone' 
                            value={areaName}
                            onChange={(e: any) => setAreaName(e.target.value)}/>
                    </Form.Group>
                    <Form.TextArea 
                        label='Description' 
                        placeholder='Description de la zone' 
                        value={areaDescription}
                        onChange={(e: any) => setAreaDescription(e.target.value)}/>
                  
                </Form>
                <br/>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => setCreateModalVisible(false)}>Annuler</Button>
            <Button color='orange' content="Confirmer" onClick={handleFormSubmit} loading={creatingArea}
            />
        </Modal.Actions>
    </Modal>
}