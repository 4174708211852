import { useEffect, useState } from 'react'

import { Button, Icon, Label } from 'semantic-ui-react'

import { CompaniesListView, CompanyCreateForm, ErrorMessage, LoadingSpinner } from '../../components'

import { CompanyService, HelperService } from '../../services'

import { Company } from '../../models'

export function CompaniesPage() {

    const [companies, setCompanies] = useState<Company[]>([])
    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(true)
    const [errorFetchingCompanies, setErrorFetchingCompanies] = useState<boolean>(false)
    const [createModalVisible, setCreateModalVisible] = useState(false)


    useEffect(() => {

            const storedComapnies = HelperService.retrieveCompanies()

            if(storedComapnies.length > 0) {
                setCompanies(storedComapnies)
                setLoadingCompanies(false)
                return
            }

            CompanyService.getCompaniesList()
                .then(data => setCompanies(data))
                .catch(_ => setErrorFetchingCompanies(true))
                .finally(() =>  setLoadingCompanies(false))

    }, [])


    const openCompanyCreateModal = () => setCreateModalVisible(true)

    const addCompanyToList = (company: Company) => {
        
        setCompanies([company, ...companies])

        HelperService.storeCompanies([company, ...companies])

    }

    return <div>
        <div className='headerRow'>
            <h3 className='mb-0'>  <Label color='orange'>{companies.length}</Label> compagnies clientes</h3>
            <Button className='size-16' color='orange' onClick={openCompanyCreateModal}>Nouveau</Button>
        </div>

        { errorFetchingCompanies && <ErrorMessage/>}
        { loadingCompanies && <LoadingSpinner/>}
        { !loadingCompanies  && !errorFetchingCompanies && <CompaniesListView companies={companies} />}
        { createModalVisible &&  <CompanyCreateForm setCreateModalVisible={setCreateModalVisible} addCompanyToList={addCompanyToList}/>}
    </div>
}