import { useState } from 'react'

import { useNavigate, Outlet } from "react-router-dom"

import { Menu, MenuItem } from 'semantic-ui-react'

import { menuMappping } from '../../constants'

import { HelperService } from '../../services'

import css from './adminsys-layout.module.css'


export function AdminsysLayoutPage() {

  const navigate = useNavigate()

  const [activeItem, setActiveItem] = useState(menuMappping[HelperService.getCurrentPath()])


  const handleItemClick = (e: any, { name }: any) => {

    if (name === 'Logout') {
      HelperService.clearStorage()
      navigate('/')
      return
    }

    // console.log('name ==>', name, menuMappping[name.toLowerCase()])

    setActiveItem(menuMappping[name.toLowerCase()])

    if (name)
      navigate(`/admin_sys/${menuMappping[name.toLowerCase()]}`)
    else
      navigate(`/admin_sys`)
  }

  return <>
    <div className={css.container}>

      <Menu secondary color='orange' size='large' className={css.menubar}>
        <MenuItem
          name=''
          active={activeItem === ''}
          onClick={handleItemClick}
          icon='home'
          color='orange'
        />
        <MenuItem
          name='Companies'
          active={activeItem === 'companies'}
          onClick={handleItemClick}
          key='companies'
          icon='list layout'
          color='orange'
        />
        <MenuItem
          name='Zones'
          active={activeItem === 'areas'}
          onClick={handleItemClick}
          key='zones'
          icon='map'
          color='orange'
        />

        <MenuItem
          name='Agencies'
          active={activeItem === 'agencies'}
          onClick={handleItemClick}
          key='agencies'
          icon='map marker alternate'
          color='orange'
        />

        <MenuItem
          name='Services'
          active={activeItem === 'services'}
          onClick={handleItemClick}
          key='services'
          icon='box'
          color='orange'
        />

        <MenuItem
          name='Utilisateurs'
          active={activeItem === 'users'}
          onClick={handleItemClick}
          key='users'
          icon='user'
        />

        <MenuItem
          name='Caisses'
          active={activeItem === 'tills'}
          onClick={handleItemClick}
          key='caisses'
          icon='box'
          color='orange'
        />
        {/* 
        <MenuItem
          name='Recouvrement'
          active={activeItem === 'recouvrement'}
          onClick={handleItemClick}
          key='money-recovery'
          icon='dollar'
          /> */}

        <MenuItem
          name='Partners'
          active={activeItem === 'partners'}
          onClick={handleItemClick}
          key='partners'
          icon='linkify'
          color='orange'
        />
        <MenuItem
          name='Réception'
          active={activeItem === 'reception'}
          onClick={handleItemClick}
          key='reception'
          icon='cart arrow down'
          color='orange'
        />

        <MenuItem
          name='Approvisionnement'
          active={activeItem === 'provisionning'}
          onClick={handleItemClick}
          key='approvisionnement'
          icon='external alternate'
          color='orange'
        />

        <MenuItem
          name='Logout'
          onClick={handleItemClick}
          icon='sign-out'
          color='orange'
        />
      </Menu>
      <br />
      <br />
      <Outlet />
    </div>
  </>
}