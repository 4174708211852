import { getAxiosInstance } from "../http";

import { Company, User } from "../models";

export class CompanyService {

    static  createCompanyWithAdmin = async (company: any): Promise<any> => {

        const instance = getAxiosInstance()

        const response = await instance.post('companies', JSON.stringify(company))
        
        return response.data
    }

    static getCompaniesList = async (): Promise<Company[]> => {

        const instance = getAxiosInstance()

        const response = await instance.get('companies')

        return response.data.map((d: any) => Company.fromJSON(d))
    }

    static getCompanyById = async (id: number): Promise<Company> => {

        const instance = getAxiosInstance()

        const response = await instance.get(`companies/${id}`)

        return Company.fromJSON(response.data)
    }

    static updateCompany = async (company: Company): Promise<any> => {

        const instance = getAxiosInstance()

        const response = await instance.put(`companies/${company.id}`, JSON.stringify(company))

        return response.data
    }

    static deleteCompany = async (id: number): Promise<any> => {

        const instance = getAxiosInstance()

        const response = await instance.delete(`companies/${id}`)

        return response.data
    }

    static getCompanyUsers = async (id: number): Promise<User[]> => {
            
            const instance = getAxiosInstance()
    
            const response = await instance.get(`companies/${id}/users`)
    
            return response.data
    }
}