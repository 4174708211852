import React from 'react';

import css from './footer.module.css';
import { Icon } from 'semantic-ui-react';

export function FooterComponent() {
    return <footer className={css.footerWrapper}>
            <p><Icon name='copyright outline'/> <strong>OKDEV Sénégal 2024</strong> | tous droits réservés.</p>
            <p><Icon name='phone volume'/> Téléphone: &nbsp; <strong>+221763983535</strong></p>
            <p><Icon name='mail'/> Email: &nbsp; <b><a href='mailto:okdev.sn@gmail.com' className='black'>okdev.sn@gmail.com</a></b></p>
    </footer>
}