import { useEffect, useState }  from 'react'

import { useNavigate, useParams } from 'react-router-dom';

import { Company } from '../../models';

import { CompanyService } from '../../services';

import { NoData, ErrorMessage, LoadingSpinner, CompanyProfileView, CompanyDeleteView } from '../../components';

import { FeedbackMessage } from '../../constants'

export function CompaniesProfilePage () {

    const { id: cId } = useParams();

    const navigate = useNavigate();

    const [company, setCompany] = useState<Company | null>(null)

    const [error, setError] = useState<boolean>(false)

    const [loading, setLoading] = useState<boolean>(true)

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

    useEffect(() => {
        setTimeout(() => { getCompanyById() }, 500)
    }, [])

    const getCompanyById = () => { 
        
        CompanyService.getCompanyById(Number(cId))

            .then((data) => setCompany(data))

            .catch((error) => setError(true))

            .finally(() => setLoading(false))
    }


    const deleteCompany = () => {

        CompanyService.deleteCompany(Number(cId))

            .then(() => {
                setShowDeleteModal(false)
                
                navigate('/admin_sys/companies/')
            })
            .catch(_ => setError(true))

        console.log('deleteCompany')
    }


    return <div>
                {loading && <LoadingSpinner />}
                
                {error && <ErrorMessage errorText={FeedbackMessage.COMPANY_NOT_FOUND} />}

                {!error && company && !loading && <CompanyProfileView c={company} showDeleteModal={setShowDeleteModal} /> }

                {!error && !company && !loading && <NoData>{FeedbackMessage.COMPANY_NOT_FOUND}</NoData> }

                {showDeleteModal && <CompanyDeleteView showDeleteModal={setShowDeleteModal} deleteCompany={deleteCompany}/>}

            </div>
}