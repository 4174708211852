import { useState } from "react"

import { Outlet, useNavigate } from "react-router-dom"

import { Menu, MenuItem } from "semantic-ui-react"

import { menuMappping } from "../../constants"

import { HelperService } from '../../services'

import css from './distributor-layout.module.css'


export function DistributorLayoutPage() {

    const navigate = useNavigate()
    const [activeItem, setActiveItem] = useState(menuMappping[HelperService.getCurrentPath()])
    const [companyName, setCompanyName] = useState(HelperService.getCompanyName())


    const handleItemClick = (e: any, { name }: any) => {

        if (name === 'Déconnexion') {
            HelperService.clearStorage()
            navigate('/')
            return
        }

        setActiveItem(menuMappping[name.toLowerCase()])
        
        navigate(`/${companyName}/distributor/${menuMappping[name.toLowerCase()]}`)
    }

    return <>
        <div className={css.container}>

            <Menu secondary color='orange' size='large'>
                <MenuItem
                    name='Accueil'
                    active={activeItem === ''}
                    onClick={handleItemClick}
                    icon='home'
                    color='orange'
                />

                <MenuItem
                    name='Réception'
                    active={activeItem === 'reception'}
                    onClick={handleItemClick}
                    key='reception'
                    icon='cart arrow down'
                    color='orange'
                />       

                <MenuItem
                    name='Approvisionnement'
                    active={activeItem === 'provisionning'}
                    onClick={handleItemClick}
                    key='approvisionnement'
                    icon='external alternate'
                    color='orange'
                />            
                <MenuItem
                    name='Partenaires'
                    active={activeItem === 'partners'}
                    onClick={handleItemClick}
                    key='partners'
                    icon='linkify'
                    color='orange'
                /> 
                <MenuItem
                    name='Déconnexion'
                    onClick={handleItemClick}
                    icon='sign-out'
                    color='orange'
                />           
            </Menu>
            <br />
            <br />
            <Outlet />
        </div>
    </>
}