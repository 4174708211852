import { useState } from 'react'
import { Button, Form, Header, Modal } from 'semantic-ui-react'

import { DropdownOptions } from '../constants'

import { Agency } from '../models'

export function AgencyUpdate({setEditModalVisible, agency}: {setEditModalVisible: Function, agency: Agency}) {

    const [agencyArea, setAgencyArea] = useState<number | undefined>(agency.area?.id)
    const [agencyName, setAgencyName] = useState<string>(agency.name || '')
    
    return <Modal
        size='tiny'
        onClose={() => setEditModalVisible(false)}
        onOpen={() => setEditModalVisible(true)}
        open={true}>
        <Modal.Content >
            <Modal.Description>
                <Header>Edition agence</Header>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Select
                            fluid
                            label='Zones'
                            options={DropdownOptions.areaOptions}
                            placeholder='Choisir une zone'
                            value={agencyArea}
                            onChange={(e: any) => setAgencyArea(e.target.textContent)}
                        />

                        <Form.Input 
                        fluid 
                        label='Nom' 
                        placeholder='Nom de la zone'
                        value={agencyName}
                        onChange={(e: any) => setAgencyName(e.target.value)} />
                    </Form.Group>
                    <Form.TextArea label='Description' placeholder='Description de la zone' />
                </Form>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => setEditModalVisible(false)}>Annuler</Button>
            <Button content="Confirmer" onClick={() => setEditModalVisible(false)} primary/>
        </Modal.Actions>
    </Modal>
}