import { Agency } from "./agency.model";
import { User } from "./user.model";
import { MobileMoney } from "./mobile-money.model";
import { Company } from "./company.model";

export class Till {
    public id?: number
    public company?: Company
    public agency?: Agency
    public user?: User
    public services?: Array<MobileMoney>

    constructor(args: {id?: number, company?: Company, agency?: Agency, user?: User, services?: MobileMoney[]}) {
        this.id = args.id
        this.company = args.company
        this.agency = args.agency
        this.user = args.user
        this.services = args.services
    }

    static fromJSON(jsonData: any): Till {
        return new Till({
            id: jsonData.id,
            company: jsonData.user.company,
            agency: Agency.fromJSON(jsonData.agency),
            user: User.fromJSON(jsonData.user),
            services: jsonData.services.map((s: any) => MobileMoney.fromJSON(s))
        })
    }
}