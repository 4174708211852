import { useState } from 'react'
import { Button, Header, Modal, Form } from 'semantic-ui-react'
import { DropdownOptions, FeedbackMessage } from '../constants'

import { MobileMoney } from '../models'
import { MobileMoneyServiceService } from '../services'
import { ErrorMessage } from './error-message'

export function MobileMoneyServiceCreateForm({setCreateModalVisible, addMobileMoneyServiceToList}: {setCreateModalVisible:  Function, addMobileMoneyServiceToList: Function}) {

    const [serviceName, setServiceName] = useState('')
    const [serviceBank, setServiceBank] = useState('')
    const [serviceType, setServiceType] = useState('')
    const [serviceCreationError, setServiceCreationError] = useState(false)

    const [creatingService, setCreatingService] = useState(false)

    const handleFormSubmit = () => {
       
        const service = new MobileMoney({name: serviceName, bank: serviceBank, type: serviceType })

        setCreatingService(true)
  
        MobileMoneyServiceService.createService(service)
            .then(service => {
                addMobileMoneyServiceToList(service)
                setCreateModalVisible(false)
            })
            .catch(__ => setServiceCreationError(true))
            .finally(() => setCreatingService(false))
    }


    return <Modal
        size='small'
        onClose={() => setCreateModalVisible(false)}
        onOpen={() => setCreateModalVisible(true)}
        open={true}>
        <Modal.Content >
            <Modal.Description>
                <Header as="h3">Nouveau Service</Header>
                { serviceCreationError && <ErrorMessage errorText={FeedbackMessage.SERVICE_CREATE_ERROR} />}
                <br/>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Select
                            fluid
                            label='Mobile Money'
                            options={DropdownOptions.mobileMoneyOptions}
                            placeholder='Choisir'
                            value={serviceName}
                            onChange={(e: any) =>  setServiceName(e.target.textContent)}
                        />
                        <Form.Select
                            fluid
                            label='Banque Payeur'
                            options={DropdownOptions.senegalBanksDropDown}
                            placeholder='Choisir'
                            value={serviceBank}
                            onChange={(e: any) => setServiceBank(e.target.textContent)}/>
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Select
                                fluid
                                label='Type de Service'
                                options={DropdownOptions.mobileMoneyTypes}
                                placeholder='Choisir'
                                value={serviceType}
                                onChange={(e: any) => setServiceType(e.target.textContent)}/>
                    </Form.Group>
                  
                </Form>
                <br/>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => setCreateModalVisible(false)}>Annuler</Button>
            <Button color='orange' content="Confirmer" onClick={handleFormSubmit} loading={creatingService}
            />
        </Modal.Actions>
    </Modal>
}