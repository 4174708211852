import { getAxiosInstance } from "../http";

import { Partner } from "../models"

export class PartnerService {

    static getPartners = async (): Promise<Partner[]> => {
  
        const instance = getAxiosInstance()

        const response = await instance.get('partners')

        const result = response.data.map((s: any) => Partner.fromJSON(s))

        return result

    }


    static createPartner = async (partner: Partner): Promise<Partner> => {
        
        const instance = getAxiosInstance()

        console.log('partner', Partner.toJSON(partner))

        const response = await instance.post('partners', Partner.toJSON(partner))

        return Partner.fromJSON(response.data)
    }

}