import { Agency } from '../models'
import { AgencyItem } from './agency-item'
import { AgencyUpdate } from './agency-update'
import { Button, Modal } from 'semantic-ui-react'
import { useState } from 'react'

// Shouldn't I use useRef ?  🤔
let idToDelete: number | null = null

let agencyToEdit: Agency = {
    name: '',
    area: {},
    tillsCount: 0,
    createdAt: new Date(),
    updatedAt: new Date()
}

export function AgencyList({agencies, userRole}: {agencies: Agency[], userRole: string}) {
    
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)

    
    const handleDeleteAgency = (id: number): void => {
        setDeleteModalVisible(true)
        idToDelete = id
    }

    const handleEditAgency = (agency: any): void => {
        setEditModalVisible(true)
        agencyToEdit = {...agency}
        console.log(agencyToEdit)
    }

    const deleteAgency = () => {
        console.log(idToDelete)
        setDeleteModalVisible(false)
    }

    return <div>
         <Modal
            size='mini'
            open={deleteModalVisible}
            onClose={() => { }}
        >
            <Modal.Header>Suppression d'agence</Modal.Header>
            <Modal.Content>
                <p>Etes-vous sûr de vouloir supprimer cet agence ?<br /> Cette action est irreversible.</p>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={() => setDeleteModalVisible(false)}>Non</Button>
                <Button negative onClick={deleteAgency}>Oui</Button>
            </Modal.Actions>
        </Modal>
        {editModalVisible && <AgencyUpdate setEditModalVisible={setEditModalVisible} agency={agencyToEdit}/>}
        {agencies.map(a => <AgencyItem key={a.id}  agency={a} showEditForm={handleEditAgency} showDeletePopup={handleDeleteAgency} userRole={userRole}/>)}
    </div>
}