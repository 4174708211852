import { getAxiosInstance } from "../http"

export class UploadService {

    static async uploadFile (file: File | undefined): Promise<any> {

        if (!file) return ''

        const instance = getAxiosInstance()

        const payload = new FormData()
    
        payload.append('file', file)

        const headers = {headers: {"Content-Type": 'multipart/form-data'}}

        const response = await instance.post('images/uploads', payload, headers)

        return response.data?.file_path || ''
    }
}