import { getAxiosInstance } from "../http";

import { Area } from "../models";

export class AreaService {

    static getAreaList = async (): Promise<Area[]> => {

        const instance = getAxiosInstance()

        const response = await instance.get('areas')

        return response.data.map((a: any) => Area.fromJSON(a))
    }

    static createArea = async (area: Area): Promise<Area> => {

        const instance = getAxiosInstance()

        const response = await instance.post('areas', JSON.stringify(area))

        return Area.fromJSON(response.data)
    }
}