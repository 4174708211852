import css from './area-list.module.css'

import { Area } from '../models'

import { AreaItem } from './area-item'

export function AreaList({areaList}: {areaList: Partial<Area>[]}) {

    return <div>
        {areaList.map(area => <AreaItem key={area.id} 
        id={area.id}
        name={area.name} 
        description={area.description} 
        agenciesCount={area.agenciesCount} 
        usersCount={area.usersCount} 
        tillsCount={area.tillsCount} 
        region={area.region} 
        department={area.department}/>)}   
    </div> 

}