import css from './login.module.css'

import { FooterComponent, LoginForm } from "../../components";

export function LoginPage() {
    return <section className={css.container}>
        <LoginForm/>
     
        <div className={css.mobileMoneyLogosRow}>
            <img src="/images/wave.jpg" alt="logo" className={css.mobileMoneyLogo}/>
            <img src="/images/ria.png" alt="logo" className={css.mobileMoneyLogo}/>
            <img src="/images/moneygram.png" alt="logo" className={css.mobileMoneyLogo}/>
            <img src="/images/orangemoney.png" alt="logo" className={css.mobileMoneyLogo}/>
            <img src="/images/kpay.png" alt="logo" className={css.mobileMoneyLogo}/>
            <img src="/images/western-union.png" alt="logo" className={css.mobileMoneyLogo}/>
            <img src="/images/freemoney.png" alt="logo" className={css.mobileMoneyLogo}/>
            <img src="/images/kash-kash.png" alt="logo" className={css.mobileMoneyLogo}/>
            <img src="/images/smallworld.png" alt="logo" className={css.mobileMoneyLogo}/>
        </div>

        <FooterComponent/>
    </section>

}