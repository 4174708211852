import { useState } from 'react'
import { Button, Header, Modal, Form, Checkbox } from 'semantic-ui-react'

import { MobileMoney, Till, User } from '../models'
import { ErrorMessage } from './error-message'
import { LoadingSpinner } from './loading-spinner'
import { HelperService, TillService, CompanyService, MobileMoneyServiceService } from '../services'
import { FeedbackMessage } from '../constants'

export function TillCreateForm({ setCreateModalVisible, addTillToList }: { setCreateModalVisible: Function, addTillToList: Function }) {

    const [step, setStep] = useState(1)

    const [agencyId, setAgencyId] = useState()
    const [agencyName, setAgencyName] = useState('')
    const [agencies, setAgencies] = useState<any[]>([])

    const [companyName, setCompanyName] = useState('')
    const [companyId, setCompanyId] = useState<number>()

    const [userId, setUserId] = useState()
    const [userFullname, setUserFullname] = useState('')
    const [users, setUsers] = useState<any[]>([])

    const [mmServices, setMmServices] = useState<MobileMoney[]>([])
    const [selectedServiceIds, setSelectedServiceIds] = useState<number[]>([])

    const [creatingTill, setCreatingTill] = useState(false)
    const [tillCreationError, setTillCreationError] = useState(false)


    const getCompanyId = (cName: string) => companies.find(c => c.text === cName)?.key

    const getAgencyId = (aName: string) => agencies.find(a => a.text === aName)?.key

    const getUserId = (fname: string) => users.find(u => u.text === fname)?.key


    const companies = HelperService.retrieveCompanies().map(company => {
        return {
            key: company.id,
            text: company.name,
            value: company.name
        }
    })


    const handleNext = () => setStep(step => step + 1)

    const handleCompanyChange = (n: string) => {

        setCompanyName(n)

        const cId = getCompanyId(n)

        setCompanyId(cId)

        if (cId) {

            const agencies = HelperService.retrieveAgencies().filter(a => a.companyId === cId)

            setAgencies(agencies.map(a => { return { key: a.id, text: a.name, value: a.name } }))
        }
    }

    const handleAgencyChange = (a: string) => {

        setAgencyName(a)

        setAgencyId(getAgencyId(a))

        fetchCompanyUsers()
    }

    const handleUserChange = (fname: string) => {

        setUserFullname(fname)

        setUserId(getUserId(fname))

        fetchMobileMoneyServices()

    }

    const handleMobileMoneySelection = (e: any) => {

        const serviceId = Number(e.target.id)

        selectedServiceIds.push(serviceId)
    }

    const fetchCompanyUsers = async () => {

        const cId = getCompanyId(companyName)

        if (cId) {

            const uList = await CompanyService.getCompanyUsers(cId)

            setUsers(uList.map((user: User) => {
                return {
                    key: user.id,
                    text: user.firstname + ' ' + user.lastname,
                    value: user.firstname + ' ' + user.lastname
                }
            }))
        }
    }

    const fetchMobileMoneyServices = async () => {

        const s = await MobileMoneyServiceService.getMobileMoneyServices()

        setMmServices(s)
    }


    const handleFormSubmit = () => {

        setCreatingTill(true)

        const payload = { companyId: companyId!, agencyId: agencyId!, userId: userId!, serviceIds: selectedServiceIds }

        TillService.createTill(payload)
            .then((till: Till) => {
                addTillToList(till)
                setCreateModalVisible(false)
            })
            .catch(() => setTillCreationError(true))
            .finally(() => setCreatingTill(false))
    }





    return <Modal
        size='small'
        onClose={() => setCreateModalVisible(false)}
        onOpen={() => setCreateModalVisible(true)}
        open={true}>
        <Modal.Content >
            <Modal.Description>
                <Header as="h3">Nouvelle caisse</Header>
                {tillCreationError && <ErrorMessage errorText={FeedbackMessage.SERVICE_CREATE_ERROR} />}
                <br />
                <br />
                <Form>
                    {
                        step === 1 &&
                        <Form.Group widths='equal'>
                            <Form.Select
                                fluid
                                label='Compagnies'
                                options={companies}
                                placeholder='Choisir une compagnie'
                                value={companyName}
                                onChange={(e: any) => handleCompanyChange(e.target.textContent)}
                            />
                        </Form.Group>
                    }

                    {
                        step === 2 &&
                        <Form.Group widths='equal'>
                            <Form.Select
                                fluid
                                label='Agences'
                                options={agencies}
                                placeholder='Choisir une agence'
                                value={agencyName}
                                onChange={(e: any) => handleAgencyChange(e.target.textContent)} />
                        </Form.Group>
                    }

                    {
                        step === 3 && <>

                            {
                                users.length === 0 ? <LoadingSpinner /> :
                                    <Form.Group widths='equal'>
                                        <Form.Select
                                            fluid
                                            label='Utilisateurs'
                                            options={users}
                                            placeholder='Choisir un utilisateur'
                                            value={userFullname}
                                            onChange={(e: any) => handleUserChange(e.target.textContent)} />
                                    </Form.Group>
                            }
                        </>
                    }

                    {
                        step === 4 && <>

                            {
                                mmServices.length === 0 ? <LoadingSpinner /> :

                                    mmServices.map((s: MobileMoney) => <div className='mb-8' key={s.id}><Checkbox toggle id={s.id} label={s.name + ' ⟹ ' + s.bank} onChange={handleMobileMoneySelection} /></div>)

                            }
                        </>
                    }
                </Form>
                <br />
                <br />
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => setCreateModalVisible(false)}>Annuler</Button>
            {step < 4 && <Button color='orange' content="Suivant" onClick={handleNext} loading={creatingTill} />}
            {step === 4 && <Button color='orange' content="Confirmer" onClick={handleFormSubmit} loading={creatingTill} />}
        </Modal.Actions>
    </Modal>
}