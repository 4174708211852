import { useEffect, useState } from 'react'

import { Button, Icon, Label, Select } from 'semantic-ui-react'

import {  AreaService, HelperService } from '../../services'

import { AreaList, AreaCreateForm, ErrorMessage, LoadingSpinner } from '../../components'

import { DropdownOptions } from '../../constants'

import { Area } from '../../models'

export function AreasPage() {

    const [areas, setAreas] = useState<Area[]>([])
    const [loadingAreas, setLoadingAreas] = useState<boolean>(true)
    const [errorFetchingAreas, setErrorFetchingAreas] = useState<boolean>(false)
    const [createModalVisible, setCreateModalVisible] = useState<boolean>(false)

    useEffect(() => {

            const storedAreas = HelperService.retrieveAreas()

            if(storedAreas.length === 0) {

                AreaService.getAreaList()
                    .then(data => setAreas(data))
                    .catch(err => setErrorFetchingAreas(true))
                    .finally(() => setLoadingAreas(false))
            } else {
                setAreas(storedAreas)
                setLoadingAreas(false)
            }
    }, [])

    const addAreaToList = (area: Area) => {

        setAreas([area, ...areas])

        HelperService.storeAreas([area, ...areas])
    }

    return <div>

        { errorFetchingAreas && <ErrorMessage />}

        { loadingAreas && <LoadingSpinner/> }

        {
            !loadingAreas && !errorFetchingAreas &&
            <>
                <div className="headerRow">
                    <div className='flex-center'>
                        <h3 className='mb-0 mr-25'><Label color='orange'>{areas.length}</Label> zones</h3>
                        <Select placeholder='Filtrer par région' options={DropdownOptions.regionOptions} className='mr-25' />
                        <Select placeholder='Filtrer par département' options={DropdownOptions.departementsOptions} />
                    </div>
                    <Button color='orange' className='size-16' onClick={() => setCreateModalVisible(true)}>Nouveau</Button>
                </div>
                <AreaList areaList={areas} />
            </>
        }

        {createModalVisible && <AreaCreateForm setCreateModalVisible={setCreateModalVisible} addAreaToList={addAreaToList} />}
    </div>
}