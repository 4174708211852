export class Company {
    public id: number;
    public name: string;
    public description: string;
    public phone: string;
    public email: string | undefined;
    public chiefName: string;
    public tillsCount: number;
    public agentsCount: number;
    public agenciesCount: number;
    public isActive: boolean;
    public admin: any;


    constructor(args:{id: number, name: string, description: string, phone: string, email?: string, chiefName: string, tillsCount: number, agentsCount: number, agenciesCount: number, isActive: boolean, admin?: any}) {
        this.id = args.id
        this.name = args.name
        this.description = args.description
        this.phone = args.phone
        this.email = args.email
        this.chiefName = args.chiefName
        this.tillsCount = args.tillsCount
        this.agentsCount = args.agentsCount
        this.agenciesCount = args.agenciesCount
        this.isActive = args.isActive
        this.admin = args.admin
    }

   static fromJSON(jsonData: any): Company {
        return new Company({
            id: jsonData.id,
            name: jsonData.name,
            description: jsonData.description || 'Aucune description',
            phone: jsonData.phone,
            email: jsonData.email ||'osmdiop@gmail.com',
            chiefName: jsonData.chief_name,
            tillsCount: jsonData.number_of_tills,
            agentsCount: jsonData.number_of_employees,
            agenciesCount: jsonData.number_of_agencies,
            isActive: jsonData.is_active,
            admin: jsonData.admin
        })
    }
}