import { useState } from "react";
import { Button, Modal, ModalActions, ModalContent, ModalHeader } from "semantic-ui-react";

export function CompanyDeleteView({showDeleteModal, deleteCompany} : {showDeleteModal: Function, deleteCompany: Function}) {

  const [deleting, setDeleting] = useState<boolean>(false)

  const confirmDeleteCompany = () => {

    setDeleting(true)

    deleteCompany()
  }

  return <div>
    <Modal
      size="mini"
      open={true}
      onClose={() => { }}
    >
      <ModalHeader>Suppression Compagnie</ModalHeader>
      <ModalContent>
        <p>Etes-vous sur de supprimer cette compagnie ?</p>
      </ModalContent>
      <ModalActions>
        <Button negative onClick={() => showDeleteModal(false)}>Non</Button>
        <Button positive onClick={() => confirmDeleteCompany()} loading={deleting}>Oui</Button>
      </ModalActions>
    </Modal>
  </div>
}