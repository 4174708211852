
export const menuMappping: any = {
  '': '',
  'admin_sys': '',
  'companies': 'companies',
  'zones': 'areas',
  'areas': 'areas',
  'agencies': 'agencies',
  'services': 'services',
  'users': 'users',
  'utilisateurs': 'users',
  'caisses': 'tills',
  'tills': 'tills',
  'recouvrement': 'recoveries',
  'transactions': 'transactions',

  //=======================> Supervisor menu mapping

  'accueil': '',
  'supervisor': '',
  'agences': 'agences',


  //=======================> Distributor menu mapping

  'distributor': '',
  'réception': 'reception',
  'reception': 'reception',
  'approvisionnement': 'provisionning',
  'provisionning': 'provisionning',
  'partenaires': 'partners',
  'partners': 'partners',

}