import { useEffect, useState } from 'react'
import { Button, Header, Modal, Form } from 'semantic-ui-react'
import { FeedbackMessage } from '../constants'

import { Partner } from '../models'
import { CompanyService, PartnerService } from '../services'
import { ErrorMessage } from './error-message'

export function PartnerCreateForm({ setCreateModalVisible, addPartnerToList, userRole }: { setCreateModalVisible: Function, addPartnerToList: Function, userRole: string }) {

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [comment, setComment] = useState('')
    const [companies, setCompanies] = useState<any>([])
    const [companyId, setCompanyId] = useState<number>()
    const [contactFullname, setContactFullname] = useState('')

    const [creatingService, setCreatingService] = useState(false)
    const [serviceCreationError, setServiceCreationError] = useState(false)

    useEffect(() => {

        if (userRole === 'Admin') {

            CompanyService.getCompaniesList()
                .then(data => { setCompanies(data.map(company => { return { key: company.id, text: company.name, value: company.id } })) })
                .catch(err => setServiceCreationError(true))
        }

    }, [])

    const handleFormSubmit = () => {

        const partner = new Partner({ companyId, name, phone, address, comment, contactFullname })

        setCreatingService(true)

        PartnerService.createPartner(partner)
            .then(service => {
                addPartnerToList(service)
                setCreateModalVisible(false)
            })
            .catch(__ => setServiceCreationError(true))
            .finally(() => setCreatingService(false))

    }


    const handleCompanyChange = (e: any,  data: any) => setCompanyId(data.value)


    return <Modal
        size='small'
        onClose={() => setCreateModalVisible(false)}
        onOpen={() => setCreateModalVisible(true)}
        open={true}>
        <Modal.Content >
            <Modal.Description>
                <Header as="h3">Nouveau partenaire</Header>
                {serviceCreationError && <ErrorMessage errorText={FeedbackMessage.PARTNER_CREATE_ERROR} />}
                <br />
                <Form>
                    {
                        userRole === 'Admin' && <Form.Select
                            fluid
                            label='Compagnie'
                            options={companies}
                            placeholder='Choisir'
                            value={companyId}
                            onChange={handleCompanyChange}
                        />
                    }

                    <Form.Group widths='equal'>
                        <Form.Input label='Nom du partenaire' placeholder='Nom du partenaire' value={name} onChange={e => setName(e.target.value)} />
                        <Form.Input fluid label='Nom du contact' placeholder='Nom du contact' value={contactFullname} onChange={e => setContactFullname(e.target.value)} />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Input label='Téléphone' placeholder='Numéro de tel' value={phone} onChange={e => setPhone(e.target.value)} />
                        <Form.Input fluid label='Adresse' placeholder='Adresse' value={address} onChange={e => setAddress(e.target.value)} />
                    </Form.Group>

                    <Form.TextArea label='Commentaire' placeholder='Commentaire' value={comment} onChange={e => setComment(e.target.value)} />
                </Form>
                <br />
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => setCreateModalVisible(false)}>Annuler</Button>
            <Button color='orange' content="Confirmer" onClick={handleFormSubmit} loading={creatingService}
            />
        </Modal.Actions>
    </Modal>
}