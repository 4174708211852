import { useEffect, useState } from "react"
import { Button, Icon, Label } from "semantic-ui-react"


import { MobileMoney } from "../../models"
import { MobileMoneyServiceService } from "../../services"
import { FeedbackMessage } from "../../constants"
import { MobileMoneyList, MobileMoneyServiceCreateForm, ErrorMessage, LoadingSpinner } from "../../components"


export function MobileMoneyPage() {

    const [mobileMoneyServices, setMobileMoneyServices] = useState<MobileMoney[]>([])
    const [loadingMobileMoneyServices, setLoadingMobileMoneyServices] = useState(true)
    const [errorFetchingMobileMoneyServices, setErrorFetchingMobileMoneyServices] = useState(false)
    const [createModalVisible, setCreateModalVisible] = useState(false)

    const addToList = (service: MobileMoney) =>  setMobileMoneyServices([...mobileMoneyServices, service])

    useEffect(() => {

        MobileMoneyServiceService.getMobileMoneyServices()
            .then(data => setMobileMoneyServices(data))
            .catch(err => setErrorFetchingMobileMoneyServices(true))
            .finally(() => setLoadingMobileMoneyServices(false))
    }, [])


    return <div>
        { loadingMobileMoneyServices && <LoadingSpinner />}
        { errorFetchingMobileMoneyServices && <ErrorMessage errorText={FeedbackMessage.SERVICE_FETCH_ERROR} />}
        { !loadingMobileMoneyServices && !errorFetchingMobileMoneyServices &&
        <div style={{width: '1000px', margin: 'auto'}}>
            <div className='headerRow'>
                    <div className='flex-center'>
                        <h3 className='mb-0 mr-25'><Label color='orange'>{mobileMoneyServices.length}</Label> &nbsp; Services mobile money</h3>
                    </div>
                    <Button color='orange' className='size-16' onClick={() => setCreateModalVisible(true)}>
                        <Icon name="add circle" />Nouveau
                    </Button>
                </div>
            <MobileMoneyList mobileMoneyServices={mobileMoneyServices} />
        </div> 
        }

        { createModalVisible && <MobileMoneyServiceCreateForm setCreateModalVisible={setCreateModalVisible} addMobileMoneyServiceToList={addToList}/> }
    </div>
}

