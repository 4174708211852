import { Message } from "semantic-ui-react";

export function ErrorMessage({errorText}: {errorText?: string}) {
    return <>
        <Message error color='red'>
            <Message.Header>Erreur</Message.Header>
            <p>{errorText ? errorText : 'Nous avons rencontré une erreur. Veuillez réessayer ou contacter les admins'}. </p>
        </Message>
        <br />
    </>
}