import { Button, Icon, Table } from 'semantic-ui-react'

import { MobileMoney } from "../models"

import { NoData } from "./no-data";

export function MobileMoneyList({ mobileMoneyServices }: { mobileMoneyServices: MobileMoney[] }) {
    return <div>
        {
            mobileMoneyServices.length === 0 ?  <NoData/> :

            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className="size-17">ID</Table.HeaderCell>
                        <Table.HeaderCell className="size-17">Logo</Table.HeaderCell>
                        <Table.HeaderCell className="size-17">Nom</Table.HeaderCell>
                        <Table.HeaderCell className="size-17">Type</Table.HeaderCell>
                        <Table.HeaderCell className="size-17">Banque Payeur</Table.HeaderCell>
                        <Table.HeaderCell className="size-17">Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {mobileMoneyServices.map(service => <Table.Row key={service.id}>
                        <Table.Cell>{service.id}</Table.Cell>
                        <Table.Cell><img src={'/images/' + service.logo} alt='' width='50' className='radius-4'/></Table.Cell>
                        <Table.Cell>{service.name}</Table.Cell>
                        <Table.Cell>{service.type}</Table.Cell>
                        <Table.Cell>{service.bank}</Table.Cell>
                        <Table.Cell>
                            <Button icon className='mr-10'><Icon name='trash alternate' /></Button>
                            <Button icon className='mr-10'><Icon name='pencil alternate' /></Button>
                        </Table.Cell>
                    </Table.Row>)}
                </Table.Body>
            </Table>
        }
    </div>

}