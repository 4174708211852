export class MobileMoney {
    public id?: number;
    public logo?: string;
    public name?: string;
    public bank?: string;
    public type?: string;

    constructor(args: {id?: number, logo?: string, name?: string, bank?: string, type?: string}) {
        this.id = args.id
        this.logo = args.logo
        this.name = args.name
        this.bank = args.bank
        this.type = args.type

    }

    static fromJSON(jsonData: any): MobileMoney {
        return new MobileMoney({
            id: jsonData.id,
            name: jsonData.name,
            bank: jsonData.bank,
            type: jsonData.service_type,
            logo: this.getMobileMoneyLogoImage(jsonData.name)
        })  
    }


    static getMobileMoneyLogoImage = (name: string): string => {
        switch(name) {
            case 'Wave': return 'wave.jpg';
            case 'Orange Money': return 'orangemoney.png';
            case 'Free Money': return 'freemoney.png';
            case 'Ria Money': return 'ria.png';
            case 'Western Union': return 'western-union.png';
            case 'Money Gram': return 'moneygram.png';
            case 'Kpay': return 'kpay.png';
            case 'Kash-Kash': return 'kash-kash.png';
            case 'Small World': return 'smallworld.png';
            default: return 'placeholder.png';
        }
    }
}

